import {AxiosInstance} from 'axios';
import {LocationsDTO} from '../../dtos/LocationDTO';

export class LocationsService {
  constructor(private api: AxiosInstance) {}

  public async getLocations(): Promise<LocationsDTO> {
    return await this.api
      .get<LocationsDTO>('/locations')
      .then((response) => response.data);
  }
}

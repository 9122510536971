/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Grid from '@mui/material/Grid';
import {FormGroup, FormLabel, InputLabel} from '@mui/material';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Settings page components
import FormField from 'forms/controls/FormField';
import Description from 'forms/controls/Description';
import AutocompleteObjectsList from 'forms/controls/AutocompleteObjectsList';
import AutocompleteStringsList from 'forms/controls/AutocompleteStringsList';
import {FieldLabeledCheckbox} from 'forms/controls/FieldLabeledCheckbox';

// Google Maps Component
import {MapComponent} from 'forms/controls/MapComponent';

import {DefaultProps} from 'utils/DefaultProps';
import {TimeUnit} from 'enums/TimeUnit';
import {trimDateTime} from 'functions/trimDateTime';

/**
 * Add or Edit offer form
 * @param param0 params and functions for form fields
 * @returns
 */
function EditAddOfferForm({
  name,
  setName,
  nameErrorMessage,
  description,
  setDescription,
  descriptionErrorMessage,
  category,
  setCategory,
  categoryErrorMessage,
  wageTimeUnit,
  setWageTimeUnit,
  wageTimeUnitErrorMessage,
  wageValue,
  setWageValue,
  wageValueErrorMessage,
  setLocationCity,
  locationGeo,
  setLocationGeo,
  locationCityErrorMessage,
  locationErrorMessage,
  isRemote,
  setIsRemote,
  categoriesList,
  validFrom,
  setValidFrom,
  validFromErrorMessage,
}: DefaultProps): JSX.Element {
  const locationError = locationErrorMessage
    ? locationErrorMessage
    : locationCityErrorMessage;
  return (
    <MDBox>
      <MDBox p={3}>
        <MDTypography variant='h5'>Offer Information</MDTypography>
      </MDBox>
      <MDBox component='form' pb={3} px={3} mb={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              label='Offer name'
              value={name}
              setValue={setName}
              required={true}
              errorMessage={nameErrorMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <AutocompleteObjectsList
              label='Category'
              value={category}
              setValue={setCategory}
              optionsList={categoriesList}
              freeSolo={true}
              listKey='name'
              required={true}
              errorMessage={categoryErrorMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Description
              description={description}
              setDescription={setDescription}
              errorMessage={descriptionErrorMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormGroup>
              <FormLabel>
                <InputLabel>
                  <div style={{fontSize: 14}}>Location</div>
                </InputLabel>
              </FormLabel>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FieldLabeledCheckbox
                    label='Is remote'
                    checked={isRemote}
                    handleChange={setIsRemote}
                  />
                  <MapComponent
                    location={locationGeo}
                    setLocation={setLocationGeo}
                    errorMessage={locationError}
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormGroup>
              <FormLabel>
                <InputLabel>
                  <div style={{fontSize: 14, marginBottom: 7}}>Wage</div>
                </InputLabel>
              </FormLabel>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <AutocompleteStringsList
                    label='Time unit'
                    value={wageTimeUnit}
                    setValue={setWageTimeUnit}
                    options={Object.values(TimeUnit)}
                    freeSolo={false}
                    errorMessage={wageTimeUnitErrorMessage}
                    disableClearable={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label='Value'
                    value={wageValue}
                    setValue={setWageValue}
                    type='number'
                    errorMessage={wageValueErrorMessage}
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label='Valid from'
              type='datetime-local'
              value={trimDateTime(validFrom)}
              setValue={setValidFrom}
              errorMessage={validFromErrorMessage}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default EditAddOfferForm;

/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable/index';

// Data redux
import {useSelector, useDispatch} from 'react-redux';
import {fetchOffers} from 'redux/reducers/offersSlice';
import {toggleSnackbar} from 'redux/reducers/snackbarSlice';
import {RootState} from 'redux/store';

// Data
import dataTableHeader from './data/dataTableHeader';
import Grid from '@mui/material/Grid';
import MDTypography from '../../../components/MDTypography';
import Lottie from 'lottie-react';
import ghostLoader from '../../../assets/animations/lottie/123936-empty-ghost.json';
import {PageLoadFailed} from '../../common/PageLoadFailed';
import {PageLoading} from '../../common/PageLoading';

/**
 * Component for displaying list of offers
 * @returns
 */
function OffersList(): JSX.Element {
  const navigate = useNavigate();

  // redux
  const {user, status: userStatus} = useSelector(
    (state: RootState) => state.login,
  );
  const {offers, status: offersStatus} = useSelector(
    (state: RootState) => state.offers,
  );
  const dispatch = useDispatch();

  // snackbar
  const {color, icon, title, content, dateTime, open} = useSelector(
    (state: RootState) => state.snackbar,
  );

  useEffect(() => {
    if (user) {
      dispatch(fetchOffers(user.employer_id));
    }
  }, [user, dispatch]);

  const renderAddOfferButton = (): JSX.Element => {
    return (
      <MDButton
        variant='gradient'
        color='info'
        onClick={() => navigate('/offers/add')}>
        Add new offer
      </MDButton>
    );
  };

  if (
    userStatus === 'loading' ||
    userStatus === 'initial' ||
    offersStatus === 'loading' ||
    offersStatus === 'initial'
  ) {
    return <PageLoading label={'Loading offers'} />;
  } else if (offersStatus === 'failed' || !offers) {
    return (
      <PageLoadFailed
        onReload={() => {
          dispatch(fetchOffers(user.employer_id));
        }}
      />
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox
          display='flex'
          justifyContent='space-between'
          alignItems='flex-start'
          mb={2}>
          {renderAddOfferButton()}
        </MDBox>
        <Card>
          {offers.length ? (
            <DataTable
              table={{
                columns: dataTableHeader,
                rows: offers,
              }}
              entriesPerPage={false}
              canSearch
            />
          ) : (
            <Grid
              container
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'>
              <Grid item xs={4}>
                <Lottie
                  style={{marginBottom: '-10px', padding: '0'}}
                  animationData={ghostLoader}
                />
              </Grid>
              <MDTypography variant='h6' fontWeight='bold'>
                Looks like there are no offers...
              </MDTypography>
              <Grid item style={{margin: '10px'}}>
                {renderAddOfferButton()}
              </Grid>
            </Grid>
          )}
        </Card>
      </MDBox>
      <MDSnackbar
        color={color}
        icon={icon}
        title={title}
        content={content}
        dateTime={dateTime}
        open={open}
        close={() => {
          dispatch(toggleSnackbar());
        }}
        onClose={() => {
          dispatch(toggleSnackbar());
        }}
        autoHideDuration={3000}
      />
    </DashboardLayout>
  );
}

export default OffersList;

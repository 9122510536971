import axios from 'axios';
import {PresignedPostUrlResponse} from './PresignedPostUrlResponse';
import {getOfferImagePresignedPostUrl} from '../client/AppClient';
import {getFileExtension} from '../utils/files';

/**
 * Uploads image for offer, returns its uuid
 * @param offerId offer id
 * @param file image file
 * @returns image id
 */
export async function uploadOfferImageToS3(offerId: string, file: File) {
  const presignedPostUrl: PresignedPostUrlResponse =
    await getOfferImagePresignedPostUrl(offerId, getFileExtension(file.name));
  const formData = new FormData();

  Object.entries(presignedPostUrl.presignedUrl.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append('file', file);

  await axios.post(presignedPostUrl.presignedUrl.url, formData);

  return presignedPostUrl.objectUUID;
}

import {CURRENT_CONFIG} from '../config';
import {LoggingLevel} from '../types/logging-level';

type ConsoleFunction = (message?: any, ...optionalParams: any[]) => void;

const _log = (logFunc: ConsoleFunction, arg?: any, ...kwargs: any) => {
  logFunc(arg, ...kwargs);
};

const debug = (arg?: any, ...kwargs: any) => {
  if (CURRENT_CONFIG.loggingLevel <= LoggingLevel.DEBUG) {
    _log(console.debug, arg, ...kwargs);
  }
};

const info = (arg?: any, ...kwargs: any) => {
  if (CURRENT_CONFIG.loggingLevel <= LoggingLevel.INFO) {
    _log(console.log, arg, ...kwargs);
  }
};

const warn = (arg?: any, ...kwargs: any) => {
  if (CURRENT_CONFIG.loggingLevel <= LoggingLevel.WARN) {
    _log(console.warn, arg, ...kwargs);
  }
};

const error = (arg?: any, ...kwargs: any) => {
  if (CURRENT_CONFIG.loggingLevel <= LoggingLevel.ERROR) {
    _log(console.error, arg, ...kwargs);
  }
};

export {debug, info, warn, error};

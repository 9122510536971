/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import {formatDate} from 'functions/formatDate';

/**
 * Component for displaying other offer's details
 * @param props client, valid from, modified
 * @returns
 */
function Details(props: {
  client: string;
  companyId: string;
  id: string;
  modified: string;
  validFrom: string;
}): JSX.Element {
  return (
    <MDBox display='flex' justifyContent='space-between' alignItems='center'>
      <MDBox>
        <MDBox mb={1}>
          <MDTypography variant='h6' fontWeight='medium'>
            Offer Details
          </MDTypography>
        </MDBox>
        <MDTypography component='p' variant='button' color='text'>
          Client: <b>{props.client ? props.client : '...'}</b>
        </MDTypography>
        <MDTypography component='p' variant='button' color='text'>
          Valid from: <b>{formatDate(props.validFrom)}</b>
        </MDTypography>
        <MDTypography component='p' variant='button' color='text'>
          Modified: <b>{formatDate(props.modified)}</b>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default Details;

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS components
import {Grid} from '@mui/material';

// MD Components
import MDBox from 'components/MDBox';

// Offer Details Components
import LocationInfo from '../LocationInfo';
import NotificationEmail from '../NotificationEmail';
import WageInfo from '../WageInfo';

import {OfferDTO} from 'dtos/OfferDTO';

/**
 * Component displaying conditional offer components;
 * display component only if offer's property exists
 * @param props offer object
 * @returns
 */
function Conditional(props: {offer: OfferDTO}): JSX.Element {
  return (
    <MDBox>
      <Grid container spacing={2}>
        {props.offer.location && (
          <Grid item xs={12} sm={6}>
            <LocationInfo location={props.offer.location} />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          {props.offer.wage && <WageInfo wage={props.offer.wage} />}
          {props.offer.notificationEmail && (
            <NotificationEmail email={props.offer.notificationEmail} />
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Conditional;

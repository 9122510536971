import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDBox from '../../components/MDBox';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import {ReactNode} from 'react';

interface PageProps {
  children: ReactNode;
}

export function Page({children}: PageProps): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {children}
    </DashboardLayout>
  );
}

import {Stage, StageConfig} from './types/config';
import {LoggingLevel} from './types/logging-level';

export const DEV_CONFIG: StageConfig = {
  loggingLevel: LoggingLevel.ALL,
  stage: 'localhost',
  isProdStage: false,
  defaultLanguage: 'pl',
};

export const ALPHA_CONFIG: StageConfig = {
  loggingLevel: LoggingLevel.ALL,
  stage: 'alpha',
  isProdStage: false,
  defaultLanguage: 'pl',
};

export const BETA_CONFIG: StageConfig = {
  loggingLevel: LoggingLevel.DEBUG,
  stage: 'beta',
  isProdStage: false,
  defaultLanguage: 'pl',
};
export const PROD_CONFIG: StageConfig = {
  loggingLevel: LoggingLevel.WARN,
  stage: 'prod',
  isProdStage: true,
  defaultLanguage: 'pl',
};
export const STAGES_CONFIG: {[key in Stage]: StageConfig} = {
  localhost: DEV_CONFIG,
  alpha: ALPHA_CONFIG,
  beta: BETA_CONFIG,
  prod: PROD_CONFIG,
};

const DEFAULT_TENANT = 'swipe';
export function getTenant(): string {
  return process.env.REACT_APP_SWIPE_TENANT || DEFAULT_TENANT;
}

export function isDefaultTenant(): boolean {
  return getTenant() === DEFAULT_TENANT;
}

export const CURRENT_CONFIG: StageConfig = DEV_CONFIG;

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import DateCell from '../components/DateCell';
import OpenFileCell from '../components/OpenFileCell';
import DefaultCell from '../components/DefaultCell';

const dataTableHeader = [
  {
    Header: 'Candidate email',
    accessor: 'candidateEmail',
    Cell: ({value}: any) => {
      return <DefaultCell value={value} />;
    },
  },
  {
    Header: 'Candidate phone number',
    accessor: 'candidatePhoneNumber',
    Cell: ({value}: any) => {
      return <DefaultCell value={value} />;
    },
  },
  {
    Header: 'Application time',
    accessor: 'created',
    Cell: ({value}: any) => {
      return <DateCell value={value} />;
    },
  },
  {
    Header: 'CV',
    accessor: 'documentUrl',
    Cell: ({value}: any) => {
      return <OpenFileCell value={value} extension={'pdf'} />;
    },
  },
];

export default dataTableHeader;

import {Alert, Input} from '@mui/material';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
} from '@react-google-maps/api';
import {Libraries} from '@react-google-maps/api/dist/utils/make-load-script-url';
import MDBox from 'components/MDBox';
import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {GOOGLE_MAPS_API_KEY} from 'utils/google-maps-api';
import {GoogleMapsLocation} from '../../../types/location';
import {toast} from 'react-toastify';
import {Loader} from '../../../components/Loader';
import FormField from '../FormField';
import MDAlert from '../../../components/MDAlert';

const center = {lat: 52.237, lng: 21.017}; // Warsaw
const libraries: Libraries = ['places'];

interface Props {
  location: GoogleMapsLocation;
  setLocation: Dispatch<SetStateAction<GoogleMapsLocation>>;
  errorMessage: string;
}

/**
 * Google Maps component
 * @param param0 location and set location function from parent component
 * @returns
 */
export function MapComponent({
  location,
  setLocation,
  errorMessage,
}: Props): JSX.Element {
  // Loads the map using API KEY
  const {isLoaded, loadError} = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  const [_, setMap] = useState(null);
  const originRef = useRef<HTMLInputElement>();

  const geo = location?.geo
    ? // TODO unify the type of lat/lon - it can be a string or number
      {
        lat: parseFloat(location.geo.lat.toString()),
        lng: parseFloat(location.geo.lon.toString()),
      }
    : center;

  const [mapCenter, setMapCenter] = useState(geo);
  const [searchResult, setSearchResult] = useState(null);
  // used for typing a city in case google maps can't be loaded
  const [fallbackCity, setFallbackCity] = useState<string>(
    location?.city || '',
  );

  const [initialPlaceString] = useState(
    location ? location.formattedAddress : '',
  );

  function onMapLoad(map: any) {
    setMap(map);
  }

  function onLoad(autocomplete: any) {
    setSearchResult(autocomplete);
  }

  useEffect(() => {
    if (loadError) {
      setLocation({
        ...location,
        city: fallbackCity,
      });
    }
  }, [loadError, fallbackCity]);

  function getCityFromPlace(place: google.maps.places.PlaceResult): string {
    const cityComponent = place.address_components.find((addressComponent) =>
      addressComponent.types.includes('locality'),
    );
    if (cityComponent) {
      return cityComponent.long_name;
    }
    return '';
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      const city = getCityFromPlace(place);
      const newPlaceId = place.place_id;
      const newFormattedAddress = place.formatted_address;
      const newLocation = place.geometry.location;

      setMapCenter(newLocation);
      setLocation({
        placeId: newPlaceId,
        city: city,
        formattedAddress: newFormattedAddress,
        geo: {lat: newLocation.lat(), lon: newLocation.lng()},
      });
    } else {
      toast.warn('Type the address', {autoClose: 1000});
    }
  }

  // This returns while map is being loaded
  if (!isLoaded) return <Loader label={'Loading map'} />;

  const renderGoogleMapsComponent = (): JSX.Element => {
    return (
      <>
        <MDBox>
          <MDBox spacing={2} mb={1}>
            <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
              <Input
                style={{width: '100%'}}
                type='text'
                ref={originRef}
                onChange={(e) => {
                  // when removing value set all values to initial
                  if (!e.target.value) {
                    setLocation({
                      placeId: '',
                      city: '',
                      formattedAddress: '',
                      geo: {lat: 0, lon: 0},
                    });
                  }
                }}
                defaultValue={initialPlaceString}
              />
            </Autocomplete>
            {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
          </MDBox>
        </MDBox>
        <MDBox mb={1} style={{height: '50vh', width: '100%'}}>
          <GoogleMap
            center={mapCenter}
            zoom={10}
            mapContainerStyle={{width: '100%', height: '100%'}}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            onLoad={(map) => onMapLoad(map)}>
            {location && location.geo && <Marker position={mapCenter} />}
          </GoogleMap>
        </MDBox>
      </>
    );
  };

  const renderFallbackForm = (): JSX.Element => {
    return (
      <MDBox>
        <MDAlert color='error' dismissible>
          Map failed to load, please enter the location in the form below
        </MDAlert>
        <FormField
          label='City'
          required={true}
          value={fallbackCity}
          setValue={setFallbackCity}
          errorMessage={errorMessage}
        />
      </MDBox>
    );
  };

  return (
    <MDBox>
      {loadError ? renderFallbackForm() : renderGoogleMapsComponent()}
    </MDBox>
  );
}

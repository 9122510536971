import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getOffersByEmployerId} from 'client/AppClient';
import {OffersDTO} from '../../dtos/OffersDTO';
import {AsyncDataStatus} from '../utils';

type OffersInitialState = {
  offers?: OffersDTO;
  status: AsyncDataStatus;
};

const initialState: OffersInitialState = {
  offers: undefined,
  status: 'initial',
};

/**
 * Get offers list from API
 */
export const fetchOffers = createAsyncThunk(
  'offers/getOffers',
  async (employerId: string) => {
    return await getOffersByEmployerId(employerId);
  },
);

const offersSlice = createSlice({
  name: 'offers',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffers.pending, (state, action) => {
        state.status = 'loading';
        state.offers = undefined;
      })
      .addCase(fetchOffers.fulfilled, (state, action) => {
        state.offers = action.payload;
        state.status = 'loaded';
      })
      .addCase(fetchOffers.rejected, (state, action) => {
        state.offers = undefined;
        state.status = 'failed';
      });
  },
});

export default offersSlice.reducer;

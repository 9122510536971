import {
  FieldValidationError,
  FieldValidationErrors,
} from '../../types/validation';
import {UpdateOfferRequest} from '../../client/models/UpdateOfferRequest';
import {OfferRequestBase} from '../../client/models/OfferRequestBase';
import {CreateOfferRequest} from '../../client/models/CreateOfferRequest';

function createError(
  loc: string[],
  msg: string | null = 'field required',
): FieldValidationError {
  return {
    loc: loc,
    msg: msg,
  };
}

function validateOffer(offer: OfferRequestBase): FieldValidationErrors {
  const errors: FieldValidationErrors = [];
  if (!offer.validFrom) {
    errors.push(createError(['body', 'validFrom']));
  }
  if (!offer.location) {
    errors.push(createError(['body', 'location'], 'Location is required'));
  }
  if (!offer.location.city) {
    errors.push(createError(['body', 'location', 'city'], 'City is required'));
  }
  if (!offer.name) {
    errors.push(createError(['body', 'name'], 'Name of the offer is required'));
  }
  if (!offer.category) {
    errors.push(createError(['body', 'category'], 'Category is required'));
  }
  if (!offer.description) {
    errors.push(
      createError(['body', 'description'], 'Description is required'),
    );
  }
  return errors;
}

export function validateNewOffer(
  offer: CreateOfferRequest,
): FieldValidationErrors {
  return validateOffer(offer);
}

export function validateUpdateOffer(
  offer: UpdateOfferRequest,
): FieldValidationErrors {
  return validateOffer(offer);
}

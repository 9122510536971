import {Link} from 'react-router-dom';
import MDTypography from '../MDTypography';
import MDButton from '../MDButton';

interface NavigableCellProps {
  value: string;
  route: string;
}
export default function NavigableCell(props: NavigableCellProps): JSX.Element {
  return (
    <MDButton component={Link} to={props.route} variant='text' color='info'>
      <MDTypography variant='caption' fontWeight='medium' color='info'>
        {props.value}
      </MDTypography>
    </MDButton>
  );
}

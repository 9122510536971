/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import ProfileOverview from 'pages/profile/EmployerProfile';
import Settings from 'pages/settings/Settings';
import AddOffer from 'pages/offers/add-offer';
import EditOffer from 'pages/offers/edit-offer';
import OffersList from 'pages/offers/offer-list/OffersList';
import ApplicationList from 'pages/application';

// Material Dashboard 2 PRO React TS components
import MDAvatar from 'components/MDAvatar';

// @mui icons
import Icon from '@mui/material/Icon';

// Images
import OfferDetails from 'pages/offers/offer-details';
import {EmployerDTO} from 'dtos/EmployerDTO';
import Home from 'pages/home/Home';
import LogOut from 'pages/authentication/components/LogOut';
import SignInPage from 'pages/authentication/pages/SignInPage';
import ResetPasswordPage from './pages/authentication/pages/ResetPasswordPage';
import {Loader} from './components/Loader';
import {AsyncResourceStatus} from './redux/utils';

export type MenuDefinition = {
  type: 'navigable' | 'divider' | 'title';
  key: string;
  // name, route and component can be undefined for dividers
  name?: string;
  route?: string;
  icon?: JSX.Element;
  subEntries?: MenuDefinition[];
  title?: string;
};

export type RouteDefinition = {
  name: string;
  route: string;
  component: JSX.Element;
  publicRoute?: boolean;
};

export const ROUTES: RouteDefinition[] = [
  {
    name: 'profile',
    route: '/profile',
    component: <ProfileOverview />,
  },
  {
    name: 'settings',
    route: '/profile/settings',
    component: <Settings />,
  },
  {
    name: 'addOffer',
    route: '/offers/add',
    component: <AddOffer />,
  },
  {
    name: 'offersList',
    route: '/offers',
    component: <OffersList />,
  },
  {
    name: 'offerEdit',
    route: '/offer/edit/:id',
    component: <EditOffer />,
  },
  {
    name: 'offerDetails',
    route: '/offer/details/:id',
    component: <OfferDetails />,
  },
  {
    name: 'offerApplications',
    route: '/offer/details/:id/applications',
    component: <ApplicationList />,
  },
  {
    name: 'home',
    route: '/',
    component: <Home />,
  },
  {
    name: 'login',
    route: '/auth/login',
    publicRoute: true,
    component: <SignInPage />,
  },
  {
    name: 'logout',
    route: '/auth/logout',
    component: <LogOut />,
  },
  {
    name: 'ResetPassword',
    route: '/auth/reset-password',
    publicRoute: true,
    component: <ResetPasswordPage />,
  },
];

export const MENU_ENTRIES: MenuDefinition[] = [
  {
    type: 'navigable',
    name: '',
    key: 'profile',
    route: '/profile',
    // by default use a loader
    icon: <Loader height={20} width={20} />,
  },
  {type: 'divider', key: 'divider-0'},
  {
    type: 'navigable',
    name: 'Offers',
    key: 'offers',
    icon: <Icon fontSize='small'>local_offer</Icon>,
    subEntries: [
      {
        type: 'navigable',
        name: 'Add Offer',
        key: 'add-offer',
        route: '/offers/add',
      },
      {
        type: 'navigable',
        name: 'Offers list',
        key: 'offers-list',
        route: '/offers',
      },
    ],
  },
  {
    type: 'navigable',
    name: 'Settings',
    key: 'profile-settings',
    route: '/profile/settings',
    icon: <Icon fontSize='medium'>settings</Icon>,
  },
  {
    type: 'navigable',
    name: 'Log out',
    key: 'logout',
    route: '/auth/logout',
    icon: <Icon fontSize='medium'>exit_to_app</Icon>,
  },
];

function setCompanyNameInRoutes(
  employerStatus: AsyncResourceStatus,
  newName?: string,
) {
  const index = MENU_ENTRIES.findIndex((item) => item.key === 'profile');
  if (employerStatus === 'failed' || employerStatus === 'notFound') {
    MENU_ENTRIES[index].name = '';
  } else {
    MENU_ENTRIES[index].name = newName ? newName : '';
  }
}

function setCompanyProfileImageInRoutes(
  employerStatus: AsyncResourceStatus,
  newImage?: string,
) {
  const index = MENU_ENTRIES.findIndex((item) => item.key === 'profile');
  if (employerStatus === 'failed' || employerStatus === 'notFound') {
    MENU_ENTRIES[index].icon = (
      <MDAvatar
        src={require('./assets/images/placeholder_image.png')}
        alt='company-icon'
        size='sm'
      />
    );
  } else {
    const icon = newImage ? (
      <MDAvatar src={newImage} alt='company-icon' size='sm' />
    ) : (
      <Loader height={20} width={20} />
    );
    MENU_ENTRIES[index].icon = icon;
  }
}

export function setCompanyInRoutes(
  employerStatus: AsyncResourceStatus,
  employer: EmployerDTO,
) {
  setCompanyNameInRoutes(employerStatus, employer?.name);
  setCompanyProfileImageInRoutes(employerStatus, employer?.profileImage);
}

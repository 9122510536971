/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS components
import {Alert} from '@mui/material';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';

// Declaring props types for FormField
interface Props {
  label?: string;
  value?: string;
  setValue?: Dispatch<SetStateAction<string>>;
  errorMessage?: string;
  [key: string]: any;
}

/**
 * Form Firld component
 * @param param0 form field props
 * @returns
 */
function FormField({
  label,
  value,
  setValue,
  errorMessage,
  ...rest
}: Props): JSX.Element {
  const [message, setMessage] = useState('');
  useEffect(() => {
    setMessage(errorMessage);
  }, [errorMessage]);

  return (
    <MDBox>
      <MDInput
        variant='standard'
        label={label}
        fullWidth
        InputLabelProps={{shrink: true}}
        defaultValue={value}
        onChange={(e: any) => {
          setValue(e.target.value);
        }}
        onClick={() => {
          setMessage('');
        }}
        error={message}
        {...rest}
      />
      {message && <Alert severity='error'>{message}</Alert>}
    </MDBox>
  );
}

// Declaring default props for FormField
FormField.defaultProps = {
  label: ' ',
};

export default FormField;

import {FormControlLabel, Checkbox, InputLabel} from '@mui/material';

interface IFieldLabeledCheckbox {
  label: string;
  checked: any;
  handleChange: (arg: boolean) => void;
}

/**
 * Checkbox component
 * @param param0 checkbox props
 * @returns
 */
export function FieldLabeledCheckbox({
  label,
  checked,
  handleChange,
}: IFieldLabeledCheckbox): JSX.Element {
  return (
    <FormControlLabel
      label={
        <InputLabel>
          <div style={{fontSize: 12}}>{label}</div>
        </InputLabel>
      }
      control={
        <Checkbox
          checked={checked ? checked : false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(!checked);
          }}
        />
      }
    />
  );
}

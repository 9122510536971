// Base URL
import {Stage} from '../types/config';
import {
  CURRENT_CONFIG,
  STAGES_CONFIG,
  getTenant,
  isDefaultTenant,
} from '../config';
import {info, warn} from '../utils/logging';

const BASE_URL = 'swipeapp.pl';

/**
 * Returns a base API url without a slash at the end.
 * Url is created based on the stage.
 * */
const getStageApiEndpoint = (stage: Stage) => {
  if (stage === 'localhost') {
    return 'http://localhost:8000';
  } else {
    const tenantName = isDefaultTenant() ? '' : `.${getTenant()}`;
    if (stage === 'prod') {
      // api.swipeapp.pl for swipe (DEFAULT TENANT)
      // api.{tenant}.swipeapp.pl for others
      return `https://api${tenantName}.${BASE_URL}`;
    }
    // api.beta.swipeapp.pl for swipe (DEFAULT TENANT)
    // api.beta.{tenant}.swipeapp.pl for others
    return `https://api.${stage}${tenantName}.${BASE_URL}`;
  }
};

const getStageFromEnvironment = (): Stage => {
  const stage = process.env.REACT_APP_STAGE;
  let usedStage: Stage | 'localhost';
  if (!stage) {
    info('Using stage from the current config');
    usedStage = CURRENT_CONFIG.stage;
  } else {
    info('Using stage based on env variable');
    if (stage === 'dev') {
      usedStage = 'localhost';
    } else {
      const availableStages = Object.keys(STAGES_CONFIG);
      if (availableStages.includes(stage)) {
        usedStage = stage as Stage;
      } else {
        warn('Invalid stage in env, fallback to beta');
        usedStage = 'beta';
      }
    }
  }
  info('Stage is:', usedStage);
  return usedStage;
};

const STAGE: Stage = getStageFromEnvironment();
const BASE_API_URL = getStageApiEndpoint(getStageFromEnvironment());

export {BASE_API_URL, STAGE};

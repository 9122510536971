import {AxiosInstance} from 'axios';
import {UpdateEmployerImageRequest} from 'client/models/UpdateEmployerImageRequest';
import {UpdateEmployerRequest} from '../models/UpdateEmployerRequest';
import {EmployerDTO} from '../../dtos/EmployerDTO';
import {OffersDTO} from '../../dtos/OffersDTO';
import {EmployerImageType} from '../../enums/EmployerImageType';
import {PresignedPostUrlResponse} from '../../api/PresignedPostUrlResponse';

export class EmployersService {
  constructor(private api: AxiosInstance) {}

  public async getEmployers() {
    return await this.api.get('/employers').then((response) => response.data);
  }

  public async getEmployer(id: string): Promise<EmployerDTO> {
    return await this.api
      .get<EmployerDTO>(`/employer/${id}`)
      .then((response) => response.data);
  }

  public async getOffersByEmployerId(employerId: string): Promise<OffersDTO> {
    return await this.api
      .get<OffersDTO>(`/employer/${employerId}/offers`)
      .then((response) => response.data);
  }

  public async putEmployer(
    id: string,
    body: UpdateEmployerRequest,
  ): Promise<EmployerDTO> {
    return await this.api
      .put<EmployerDTO>(`/employer/${id}`, body)
      .then((response) => response.data);
  }

  public async getPresignedUrlForEmployerImages(
    id: string,
    imageType: EmployerImageType,
    imageExtension: string,
  ): Promise<PresignedPostUrlResponse> {
    return await this.api
      .post<PresignedPostUrlResponse>(`/employer/${id}/presigned_url`, {
        imageType: imageType,
        imageExtension: imageExtension,
      })
      .then((response) => response.data);
  }

  public async putEmployerImage(
    id: string,
    body: UpdateEmployerImageRequest,
  ): Promise<EmployerDTO> {
    return await this.api
      .put<EmployerDTO>(`/employer/${id}/image`, body)
      .then((response) => response.data);
  }
}

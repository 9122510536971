import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {AxiosResponse} from 'axios';

import {UserDTO} from '../../dtos/UserDTO';
import {AsyncResourceStatus} from '../utils';
import {toast} from 'react-toastify';
import {getUser} from '../../client/AppClient';

type UserStatus = {
  token: string;
  user?: UserDTO;
  status: AsyncResourceStatus;
};

const initialState: UserStatus = {
  token: '',
  user: undefined,
  status: 'initial',
};

/**
 * Get user from API
 */
export const fetchUser = createAsyncThunk(
  'user/getUser',
  async (arg, {rejectWithValue}) => {
    try {
      const response = await getUser();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  },
);

const loginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    logOut: (state) => {
      if (state.token) {
        state.token = null;
        state.user = null;
        state.status = 'initial';
        toast.success('User logged out', {autoClose: 1000});
      }
    },
    saveToken: (state, action) => {
      state.status = 'initial';
      state.token = action.payload.accessToken;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.user = null;
        state.status = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = 'loaded';
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.user = null;
        state.token = null;
        const errorPayload = action.payload as AxiosResponse;
        if (errorPayload.status === 400) {
          state.status = 'notFound';
          toast.error('User not found', {autoClose: 1000});
        } else {
          state.status = 'failed';
          toast.error('Failed to load user', {autoClose: 1000});
        }
      });
  },
});

export default loginSlice.reducer;
export const {logOut, saveToken} = loginSlice.actions;

import {AxiosResponse} from 'axios';

export type ValidationErrorDetail = {
  loc?: string[];
  msg?: string;
  type?: string;
};

export type ValidationError = {
  detail: ValidationErrorDetail[];
};

export type GeneralError = {
  detail: {
    msg: string;
    code: string;
  };
};

export type GeneralErrorBase = {
  detail: GeneralError;
};

export type ApiError = GeneralErrorBase | GeneralError | ValidationError;

export const isGeneralError = (error: ApiError): error is GeneralError => {
  return (
    (error as GeneralErrorBase).detail !== undefined &&
    !Array.isArray(error as GeneralError)
  );
};

export const isValidationError = (
  error: ApiError,
): error is ValidationError => {
  return (
    (error as ValidationError).detail !== undefined &&
    Array.isArray((error as ValidationError).detail)
  );
};

enum AuthErrorCodes {
  JWTError = 'auth-403-1',
  ExpiredSignatureError = 'auth-403-2',
  JWTClaimsError = 'auth-403-3',
}

export const isTokenExpiredError = (error: ApiError): boolean => {
  return (
    isGeneralError(error) &&
    error.detail.code === AuthErrorCodes.ExpiredSignatureError
  );
};

export const isTokenExpiredErrorResponse = (
  response: AxiosResponse,
): boolean => {
  return response?.status === 403 && isTokenExpiredError(response.data);
};

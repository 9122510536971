import axios from 'axios';
import {EmployerImageType} from 'enums/EmployerImageType';
import {getEmployerImagePresignedPostUrl} from '../client/AppClient';
import {getFileExtension} from '../utils/files';

/**
 * Uploads image for employer, returns its uuid
 * @param employerId employer id
 * @param file image file
 * @param imageType image type EmployerImageType
 * @returns image id
 */
export async function uploadEmployerImageToS3(
  employerId: string,
  file: File,
  imageType: EmployerImageType,
) {
  const presignedPostUrl = await getEmployerImagePresignedPostUrl(
    employerId,
    imageType,
    getFileExtension(file.name),
  );
  const formData = new FormData();

  Object.entries(presignedPostUrl.presignedUrl.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append('file', file);

  await axios.post(presignedPostUrl.presignedUrl.url, formData);

  return presignedPostUrl.objectUUID;
}

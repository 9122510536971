/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {Dispatch, SetStateAction, useEffect, useRef} from 'react';

// Dropzone components
import Dropzone from 'dropzone';

// Dropzone styles
import 'dropzone/dist/dropzone.css';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';

// Custom styles for the MDDropzone
import DropzoneRoot from 'utils/DropzoneRoot';

// Material Dashboard 2 PRO React context
import {useMaterialUIController} from 'context';

// Declaring props types for MDDropzone
interface Props {
  setDropzoneInstance: Dispatch<SetStateAction<Dropzone>>;
  options: {
    [key: string | number]: any;
  };
}

/**
 * Component for choosing image
 * @param param0 setDropzoneInstance function setting dropzone instance in parent component
 * @returns
 */
function OfferDropzone({setDropzoneInstance, options}: Props): JSX.Element {
  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  Dropzone.autoDiscover = false;

  const formHTML = document.createElement('form');
  const dropzoneRef = useRef<HTMLFormElement | null>(formHTML);

  useEffect(() => {
    function createDropzone(): Dropzone {
      return new Dropzone(dropzoneRef.current, {...options});
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0) {
        Dropzone.instances.forEach((dz) => dz.destroy());
      }
    }

    setDropzoneInstance(createDropzone());

    return () => removeDropzone();
  }, [options, setDropzoneInstance]);

  return (
    <>
      <DropzoneRoot
        action='#'
        ref={dropzoneRef}
        className='form-control dropzone'
        ownerState={{darkMode}}>
        <MDBox className='fallback' bgColor='transparent'>
          <input
            name='profile-picture'
            type='file'
            accept='image/png, image/jpeg'
            id='profile-picture'
            className='hidden'
          />
        </MDBox>
      </DropzoneRoot>
    </>
  );
}

export default OfferDropzone;

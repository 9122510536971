import {AxiosInstance} from 'axios';
import {ApplicationsDTO} from '../../dtos/ApplicationDTO';

export class ApplicationService {
  constructor(private api: AxiosInstance) {}

  public async getApplicationsForOffer(
    offerId: string,
  ): Promise<ApplicationsDTO> {
    return this.api
      .get<ApplicationsDTO>(`/offer/${offerId}/applications`)
      .then((response) => response.data);
  }
}

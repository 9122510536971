import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import MDButton from 'components/MDButton';

interface Props {
  title: string;
  warningText: string;
  visibility: boolean;
  closeFunction: () => void;
  actionFunction: () => void;
  actionText: string;
}

export const WarningDialog = ({
  title,
  warningText,
  visibility,
  closeFunction,
  actionFunction,
  actionText,
}: Props) => {
  return (
    <Dialog open={visibility} onClose={() => closeFunction()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{warningText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MDButton
              variant='gradient'
              color='light'
              fullWidth
              onClick={() => closeFunction()}>
              back
            </MDButton>
          </Grid>
          <Grid item xs={6}>
            <MDButton
              variant='gradient'
              color='warning'
              fullWidth
              onClick={() => actionFunction()}>
              {actionText}
            </MDButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {Grid} from '@mui/material';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {logOut} from 'redux/reducers/loginSlice';
import {useDispatch} from 'react-redux';
import {Loader} from '../../../components/Loader';

/**
 * Redirect to SignIn view
 * @returns
 */
function LogOut(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logOut());
    navigate('/auth/login');
  }, [dispatch]);

  return (
    <>
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{minHeight: '100vh'}}>
        <Grid item xs={3}>
          <Loader label={'Logging out'} />
        </Grid>
      </Grid>
    </>
  );
}

export default LogOut;

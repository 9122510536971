/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {Navigate} from 'react-router-dom';

/**
 * Redirect to offers list
 * @returns
 */
function Home(): JSX.Element {
  return <Navigate to={'/offers'} />;
}

export default Home;

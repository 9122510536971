/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import {WageOfferDTO} from 'dtos/WageOfferDTO';

/**
 * Component for displaying offer's wage
 * @param props wage object
 * @returns
 */
function WageInfo(props: {wage: WageOfferDTO}): JSX.Element {
  return (
    <MDBox
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      mb={1}>
      <MDBox>
        <MDTypography variant='h6' fontWeight='medium' mb={1}>
          Wage
        </MDTypography>

        {props.wage.timeunit && (
          <MDBox>
            <MDTypography
              component='p'
              variant='button'
              color='text'
              textAlign='justify'>
              Time unit: <b>{props.wage.timeunit}</b>
            </MDTypography>
          </MDBox>
        )}

        {props.wage.value && (
          <MDBox>
            <MDTypography
              component='p'
              variant='button'
              color='text'
              textAlign='justify'>
              Value: <b>{props.wage.value}</b>
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}

export default WageInfo;

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React TS Base Styles
import {EmployerDTO} from 'dtos/EmployerDTO';
import DescriptionWithHTML from 'pages/offers/offer-details/components/Description';

// Declaring props types for ProfileInfoCard
interface Props {
  employerData: EmployerDTO;
}

/**
 * Component for displaying company information
 * @param param0 company (employer) object
 * @returns
 */
function CompanyProfileInfo({employerData}: Props): JSX.Element {
  function renderItem(label: string, value: string) {
    return (
      <MDBox key={label} display='flex' py={1} pr={2}>
        <MDTypography
          variant='button'
          fontWeight='bold'
          textTransform='capitalize'>
          {label}: &nbsp;
        </MDTypography>
        <MDTypography variant='button' fontWeight='regular' color='text'>
          {value}
        </MDTypography>
      </MDBox>
    );
  }

  return (
    <MDBox sx={{height: '100%'}}>
      <MDBox
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        pt={2}
        px={2}>
        <MDTypography
          variant='h6'
          fontWeight='medium'
          textTransform='capitalize'>
          company information
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox mb={2} lineHeight={1}>
          <DescriptionWithHTML
            description={employerData.description}></DescriptionWithHTML>
        </MDBox>
        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>
        <MDBox>
          {renderItem('company name', employerData.name)}
          {renderItem('company size', employerData.companySize)}
          {renderItem('primary sector', employerData.primarySector)}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default CompanyProfileInfo;

// Filter Error Messages Functions

import {ValidationErrorDetail} from 'utils/ValidationErrorDetail';

/**
 * Returns error message found by given labels
 * @param array all error messages from response
 * @param listOfStrings list of labels to find
 * @returns first found error message or empty string if not found
 */
export function returnErrorMessageByNames(
  array: ValidationErrorDetail[],
  listOfStrings: string[],
): string {
  const result: ValidationErrorDetail[] = filterArrayByStrings(
    array,
    listOfStrings,
  );
  return result ? result[0]?.msg : '';
}

/**
 * Returns array of errors matching witch given labels
 * @param array error messages array
 * @param listOfStrings list of labels to find
 * @returns array of found matching errors
 */
export function filterArrayByStrings(
  array: ValidationErrorDetail[],
  listOfStrings: string[],
): ValidationErrorDetail[] {
  return array.filter((obj) => {
    return obj.loc.length === listOfStrings.length
      ? obj.loc.every((r) => listOfStrings.includes(r))
      : false;
  });
}

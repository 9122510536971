import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getCategories} from 'client/AppClient';
import {CategoryDTO} from 'dtos/CategoryDTO';
import {AsyncDataStatus} from '../utils';

type CategoriesState = {
  categories?: CategoryDTO[];
  status: AsyncDataStatus;
};

const initialState: CategoriesState = {
  categories: undefined,
  status: 'initial',
};

/**
 * Get categories list from API
 */
export const fetchCategories = createAsyncThunk(
  'categories/getCategories',
  async () => {
    return await getCategories();
  },
);

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.status = 'loaded';
      })
      .addCase(fetchCategories.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default categoriesSlice.reducer;

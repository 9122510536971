/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import EmployerImageDropzone from './EmployerImageDropzone';
import {EmployerImageType} from 'enums/EmployerImageType';
import {Grid} from '@mui/material';

function ChangeImages(): JSX.Element {
  // 1. profile
  // 2. background
  // 3. brand

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card id='change-images'>
            <MDBox p={2}>
              <MDTypography variant='h5' mb={1}>
                Profile Image
              </MDTypography>
              <EmployerImageDropzone
                options={{
                  addRemoveLinks: true,
                  multiple: false,
                  maxFiles: 0, // if more than 0 files chosen
                  maxfilesexceeded: function () {
                    // trigger function
                    const len = this.files.length - 1;
                    for (let i = 0; i < len; i++) {
                      this.removeFile(this.files[i]);
                    }
                  },
                }}
                employerImageType={
                  EmployerImageType.PROFILE
                }></EmployerImageDropzone>
            </MDBox>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card id='change-images'>
            <MDBox p={2}>
              <MDTypography variant='h5' mb={1}>
                Background Image
              </MDTypography>
              <EmployerImageDropzone
                options={{
                  addRemoveLinks: true,
                  multiple: false,
                  maxFiles: 0, // if more than 0 files choosed
                  maxfilesexceeded: function () {
                    // trigger function
                    const len = this.files.length - 1;
                    for (let i = 0; i < len; i++) {
                      this.removeFile(this.files[i]);
                    }
                  },
                }}
                employerImageType={
                  EmployerImageType.BACKGROUND
                }></EmployerImageDropzone>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default ChangeImages;

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {Dispatch, SetStateAction, useMemo} from 'react';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import OfferDropzone from '../OfferDropzone';
import Dropzone from 'dropzone';

// Declaring props types
interface Props {
  setDropzoneInstance: Dispatch<SetStateAction<Dropzone>>;
}

/**
 * Component for adding image to offer
 * @param param0 setDropzoneInstance function setting dropzone instance in parent component
 * @returns
 */
function AddOfferImage({setDropzoneInstance}: Props): JSX.Element {
  return (
    <MDBox p={2}>
      <MDTypography variant='h5'>Offer Image</MDTypography>
      <MDBox mt={3}>
        {useMemo(
          () => (
            <OfferDropzone
              options={{
                addRemoveLinks: true,
                multiple: false,
                maxFiles: 0, // if more than 0 files choosed
                maxfilesexceeded: function () {
                  // trigger function
                  const len = this.files.length - 1;
                  for (let i = 0; i < len; i++) {
                    this.removeFile(this.files[i]);
                  }
                },
              }}
              setDropzoneInstance={setDropzoneInstance}
            />
          ),
          [],
        )}
      </MDBox>
    </MDBox>
  );
}

export default AddOfferImage;

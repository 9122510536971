import {Page} from './Page';
import Grid from '@mui/material/Grid';
import MDTypography from '../../components/MDTypography';
import {Icon} from '@mui/material';
import Lottie from 'lottie-react';
import {useMemo} from 'react';
import failedLoading from '../../assets/animations/lottie/54117-invalid.json';
import MDButton from '../../components/MDButton';

interface PageLoadFailedProps {
  label?: string;
  onReload?: () => void;
}

export function PageLoadFailed({
  label,
  onReload,
}: PageLoadFailedProps): JSX.Element {
  const headerText = useMemo(() => {
    return label ? label : 'Failed to load data.';
  }, [label]);
  return (
    <Page>
      <Grid
        container
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'>
        <Grid item xs={4} sm={3} md={2}>
          <Lottie
            style={{marginBottom: '-10px', padding: '0'}}
            animationData={failedLoading}
          />
        </Grid>
        <Grid item>
          <MDTypography variant='caption' color='text' fontWeight='regular'>
            {headerText}
          </MDTypography>
        </Grid>
        {onReload && (
          <Grid>
            <MDTypography variant='caption' color='text'>
              Click the refresh button to try loading it again.
            </MDTypography>
            <MDButton variant='text' color='info' onClick={onReload}>
              <MDTypography variant='caption' color='primary'>
                <Icon color='inherit'>refresh</Icon>
              </MDTypography>
            </MDButton>
          </Grid>
        )}
      </Grid>
    </Page>
  );
}

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getEmployerById, putEmployer} from 'client/AppClient';
import {EmployerDTO} from '../../dtos/EmployerDTO';
import {UpdateEmployerRequest} from '../../client/models/UpdateEmployerRequest';
import {AsyncResourceStatus} from '../utils';
import {AxiosResponse} from 'axios';

type EmployerState = {
  // when loading then undefined
  employer?: EmployerDTO;
  status: AsyncResourceStatus;
  // use for loaders when updating
  updating: boolean;
  updatingFailed?: boolean;
};

const initialState: EmployerState = {
  employer: undefined,
  status: 'initial',
  updating: false,
  updatingFailed: undefined,
};

/**
 * Get employer by given id
 */
export const fetchEmployerById = createAsyncThunk(
  'employer/getEmployerById',
  async (id: string): Promise<EmployerDTO> => {
    return await getEmployerById(id);
  },
);

export const updateEmployer = createAsyncThunk(
  'employer/putEmployer',
  async (
    data: {
      id: string;
      body: UpdateEmployerRequest;
    },
    {rejectWithValue},
  ) => {
    const {id, body} = data;
    try {
      return await putEmployer(id, body);
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  },
);

const employerSlice = createSlice({
  name: 'employer',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployerById.pending, (state) => {
        state.employer = undefined;
        state.status = 'loading';
      })
      .addCase(fetchEmployerById.fulfilled, (state, action) => {
        state.employer = action.payload;
        state.status = 'loaded';
      })
      .addCase(fetchEmployerById.rejected, (state, action) => {
        state.employer = undefined;
        const errorPayload = action.payload as AxiosResponse;
        if (errorPayload.status === 400) {
          state.status = 'notFound';
        } else {
          state.status = 'failed';
        }
      })
      .addCase(updateEmployer.pending, (state) => {
        // don't change employer data here
        state.updatingFailed = undefined;
        state.updating = true;
        state.status = 'loading';
      })
      .addCase(updateEmployer.fulfilled, (state, action) => {
        state.employer = action.payload;
        state.updating = false;
        state.updatingFailed = false;
        state.status = 'loaded';
      })
      .addCase(updateEmployer.rejected, (state) => {
        state.updatingFailed = true;
        state.updating = false;
      });
  },
});

export default employerSlice.reducer;

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import {OfferDTO} from 'dtos/OfferDTO';

import Icon from '@mui/material/Icon';

/**
 * Component for displaying offer's information
 * @param props offer object
 * @returns
 */
function OfferInfo(props: {offer: OfferDTO}): JSX.Element {
  return (
    <MDBox>
      <MDBox>
        <MDTypography variant='h5'>
          <b>{props.offer.name}</b>
        </MDTypography>
      </MDBox>
      <MDBox display='flex' alignItems='center'>
        <MDBox>
          <MDTypography variant='button' color='secondary'>
            <Icon sx={{fontWeight: 'bold'}}>location_on</Icon>
          </MDTypography>
          <MDTypography px={1} variant='button' color='text'>
            {props.offer.city}
          </MDTypography>
        </MDBox>
        <MDBox px={1}>
          <MDTypography variant='button' color='secondary'>
            <Icon sx={{fontWeight: 'bold'}}>category</Icon>
          </MDTypography>
          <MDTypography px={1} variant='button' color='text'>
            {props.offer.category}
          </MDTypography>
        </MDBox>
        {props.offer.isRemote && (
          <MDBox px={1}>
            <MDTypography variant='button' color='secondary'>
              <Icon sx={{fontWeight: 'bold'}}>check</Icon>
            </MDTypography>
            <MDTypography px={1} variant='button' color='text'>
              Remote
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}

export default OfferInfo;

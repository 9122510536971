import MDBox from '../../../components/MDBox';
import MDInput from '../../../components/MDInput';
import {Alert} from '@mui/material';
import MDButton from '../../../components/MDButton';
import {Dispatch, SetStateAction, useState} from 'react';
import {logIn} from '../../../client/AppClient';
import {saveToken} from '../../../redux/reducers/loginSlice';
import {useDispatch} from 'react-redux';
import {tokenValid} from '../../../redux/reducers/settingsSlice';

interface LoginFormProps {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  onLoginCompleted?: (loginResult: boolean) => void;
}

export const LoginForm = ({
  setIsLoading,
  onLoginCompleted,
}: LoginFormProps): JSX.Element => {
  const dispatch = useDispatch();

  const [loginUsername, setEmail] = useState<string>('');
  const [loginPassword, setPassword] = useState<string>();

  const [showLoginError, setShowLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');

  async function loginFunction() {
    setIsLoading(true);
    const data = {
      username: loginUsername,
      password: loginPassword,
    };
    let loginSuccessful = false;
    await logIn(data)
      .then((token) => {
        loginSuccessful = true;
        dispatch(tokenValid());
        dispatch(saveToken(token));
        // don't set is loading to false because saveToken will cause redirect,
        // so setIsLoading(true) effect will be done on unmounted component
      })
      .catch((error) => {
        setShowLoginError(true);
        if (error.response?.status === 401) {
          setLoginErrorMessage('Invalid credentials');
        } else {
          setLoginErrorMessage('Failed to get response from server');
        }
      });
    if (onLoginCompleted) {
      onLoginCompleted(loginSuccessful);
    }
  }

  return (
    <MDBox component='form' role='form'>
      <MDBox mb={2}>
        <MDInput
          type='username'
          label='Username'
          defaultValue={loginUsername}
          onChange={(e: any) => {
            setEmail(e.target.value);
            setShowLoginError(false);
          }}
          fullWidth
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          type='password'
          label='Password'
          defaultValue={loginPassword}
          onChange={(e: any) => {
            setPassword(e.target.value);
            setShowLoginError(false);
          }}
          fullWidth
        />
      </MDBox>
      <MDBox mb={2}>
        {showLoginError && <Alert severity='error'>{loginErrorMessage}</Alert>}
      </MDBox>
      <MDBox mt={4} mb={1}>
        <MDButton
          variant='gradient'
          color='info'
          onClick={loginFunction}
          fullWidth>
          sign in
        </MDButton>
      </MDBox>
    </MDBox>
  );
};

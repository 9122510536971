import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import {LoginForm} from './LoginForm';
import {useEffect, useState} from 'react';
import MDButton from '../../../components/MDButton';
import {ResetPasswordForm} from './ResetPasswordForm';
import {Loader} from '../../../components/Loader';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../redux/store';
import MDTypography from '../../../components/MDTypography';
import {logOut} from 'redux/reducers/loginSlice';

type DialogForm = 'login' | 'resetPassword';

export const LoginDialog = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {tokenNotValid} = useSelector((state: RootState) => state.settings);
  const [currentForm, setCurrentForm] = useState<DialogForm>('login');
  const [isVisible, setIsVisible] = useState<boolean>(tokenNotValid);

  useEffect(() => {
    console.log('token not valid?', tokenNotValid);
    // if (tokenNotValid) {
    //   // set only when token is set to invalid
    //   setIsVisible(tokenNotValid);
    // }
    setIsVisible(tokenNotValid);
  }, [tokenNotValid]);

  const renderLoginForm = (): JSX.Element => {
    if (isLoading) {
      return <Loader label={'Logging in'} />;
    }
    return (
      <>
        <Grid item>
          <LoginForm
            setIsLoading={setIsLoading}
            onLoginCompleted={onLoginCompleted}
          />
        </Grid>
        <Grid item>
          <MDTypography variant={'caption'}>
            <MDButton
              variant={'text'}
              color='info'
              onClick={() => setCurrentForm('resetPassword')}>
              Reset password
            </MDButton>
          </MDTypography>
        </Grid>
      </>
    );
  };

  const onLoginCompleted = (loginResult: boolean) => {
    if (loginResult) {
      toast.success('Refreshed credentials successfully');
      setIsVisible(false);
    }
    setIsLoading(false);
  };

  const renderResetPasswordForm = (): JSX.Element => {
    if (isLoading) {
      return <Loader label={'Resetting password'} />;
    }
    return (
      <>
        <Grid>
          <ResetPasswordForm />
        </Grid>
        <Grid item>
          <MDButton
            variant={'text'}
            color='info'
            onClick={() => setCurrentForm('resetPassword')}>
            Return to login form
          </MDButton>
        </Grid>
      </>
    );
  };

  const logout = () => {
    setIsLoading(false);
    setIsVisible(false);
    dispatch(logOut());
  };

  return (
    <>
      <Dialog open={isVisible}>
        <DialogTitle>
          <>User logged out</>
          <IconButton
            aria-label='close'
            onClick={() => setIsVisible(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The default timeout after login is 30 minutes. After that period you
            need to log in again. You may close the dialog but you will not be
            able to apply any changes.
          </DialogContentText>
          <DialogContentText>
            {currentForm === 'login' ? (
              <>Type log in data.</>
            ) : (
              <>Type e-mail to reset password</>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction={'column'}
            justifyContent='center'
            alignItems='center'
            spacing={1}>
            {currentForm === 'login'
              ? renderLoginForm()
              : renderResetPasswordForm()}
            <MDTypography variant={'caption'}>
              If you ended your work you can
              <MDButton variant={'text'} color='info' onClick={logout}>
                log out
              </MDButton>
            </MDTypography>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

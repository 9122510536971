import MDBox from '../../../components/MDBox';
import MDInput from '../../../components/MDInput';
import MDButton from '../../../components/MDButton';

export const ResetPasswordForm = (): JSX.Element => {
  return (
    <MDBox component='form' role='form'>
      <MDBox mb={4}>
        <MDInput type='email' label='Email' variant='standard' fullWidth />
      </MDBox>
      <MDBox mt={6} mb={1}>
        <MDButton variant='gradient' color='info' fullWidth>
          reset
        </MDButton>
      </MDBox>
    </MDBox>
  );
};

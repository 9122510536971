/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from 'App';

// Material Dashboard 2 PRO React TS Context Provider
import {MaterialUIControllerProvider} from 'context';

// Import translations
import './i18n/config';
import {Suspense} from 'react';
import 'react-toastify/dist/ReactToastify.css';

// Redux
import {persistor, store} from './redux/store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {Loader} from './components/Loader';
import {ToastContainer} from 'react-toastify';

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
            <ToastContainer />
          </PersistGate>
        </Provider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root'),
);

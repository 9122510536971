/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS components
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

/**
 * Component for displaying notification email
 * @param props email as string
 * @returns
 */
function NotificationEmail(props: {email: string}): JSX.Element {
  return (
    <MDBox
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      mb={1}>
      <MDBox>
        <MDTypography variant='h6' fontWeight='medium' mb={1}>
          Notification Email
        </MDTypography>

        <MDBox display='flex'>
          <MDTypography variant='body2' color='text'>
            <Icon sx={{fontWeight: 'bold'}}>email</Icon>
          </MDTypography>
          <MDTypography variant='body2' color='text' textAlign='justify' mx={1}>
            {props.email}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default NotificationEmail;

/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

// @mui material components
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

// AddOffer page components
import AddOfferImage from './components/AddOfferImage';

// Other components
import {InfoDialog} from 'dialogs/InfoDialog';
import EditAddOfferForm from 'forms/components/EditAddOfferForm';

// Data redux
import {useDispatch, useSelector} from 'react-redux';
import {fetchLocations} from 'redux/reducers/locationsSlice';
import {fetchCategories} from 'redux/reducers/categoriesSlice';
import {RootState} from 'redux/store';

// Api calls
import {CreateOfferRequest} from 'client/models/CreateOfferRequest';
import {postOffer, putOfferImage} from 'client/AppClient';
import {uploadOfferImageToS3} from 'api/uploadOfferImage';

import {TimeUnit} from 'enums/TimeUnit';
import {returnErrorMessageByNames} from 'functions/filterErrorMessages';
import Dropzone from 'dropzone';
import {PageLoadFailed} from '../../common/PageLoadFailed';
import {PageLoading} from '../../common/PageLoading';
import {toast} from 'react-toastify';
import {validateNewOffer} from '../validateOffer';

function getSteps(): string[] {
  return ['1. Offer Info', '2. Offer Image'];
}

/**
 * Component for adding new offer
 * @returns
 */
function AddOffer(): JSX.Element {
  // redux
  const {user} = useSelector((state: RootState) => state.login);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Step
  const [activeStep, setActiveStep] = useState<number>(0);
  const steps = getSteps();
  const isLastStep: boolean = activeStep === steps.length - 1;

  const handleNext = () => {
    if (activeStep === 0) {
      const body: CreateOfferRequest = getUploadOfferBody();
      const validated = validateNewOffer(body);
      if (validated.length) {
        setFieldsErrorMessages([...validated]);
        toast.error('Please fix validation errors', {autoClose: 1000});
      } else {
        setActiveStep(1);
      }
    } else {
      setActiveStep((currStep) => currStep + 1);
    }
  };
  const handleBack = () => setActiveStep((currStep) => currStep - 1);

  // Form State
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [wageTimeUnit, setWageTimeUnit] = useState(TimeUnit.HOUR);
  const [wageValue, setWageValue] = useState(0);
  const [locationCity, setLocationCity] = useState('');
  const [locationGeo, setLocationGeo] = useState(undefined);
  const [locationPerimeter, setLocationPerimeter] = useState(10);
  const [isRemote, setIsRemote] = useState(false);
  const [validFrom, setValidFrom] = useState(new Date().toISOString());
  const [imageName] = useState('');

  const [dropzoneInstance, setDropzoneInstance] = useState<Dropzone>(null);

  // Fields Error Messages
  const [fieldsErrorMessages, setFieldsErrorMessages] = useState([]);

  // Dialogs
  const [showAddModal, setShowAddModal] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const showNewOfferModal = () => {
    setShowAddModal(true);
  };
  // Redux
  const {locations, status: locationsStatus} = useSelector(
    (state: RootState) => state.locations,
  );
  const {categories, status: categoriesStatus} = useSelector(
    (state: RootState) => state.categories,
  );

  const refreshAll = () => {
    dispatch(fetchLocations());
    dispatch(fetchCategories());
  };

  useEffect(() => {
    console.log('files are', dropzoneInstance?.files);
  }, [dropzoneInstance]);

  useEffect(() => {
    if (locationsStatus === 'initial') {
      dispatch(fetchLocations());
    }
    if (categoriesStatus === 'initial') {
      dispatch(fetchCategories());
    }
  }, [
    dispatch,
    locationsStatus,
    categoriesStatus,
    fetchLocations,
    fetchCategories,
  ]);

  if (locationsStatus === 'failed' || categoriesStatus === 'failed') {
    return (
      <PageLoadFailed label={'Failed to load data'} onReload={refreshAll} />
    );
  }

  if (!categories || !locations) {
    return <PageLoading />;
  }

  if (sendingData) {
    return <PageLoading label={'Adding offer'} />;
  }

  /**
   * Upload new offer
   */
  async function uploadOffer() {
    const body: CreateOfferRequest = getUploadOfferBody();
    const imageFile = dropzoneInstance?.files?.at(0);
    const validated = validateNewOffer(body);
    if (validated.length) {
      setFieldsErrorMessages([...validated]);
      toast.error('Please fix validation errors', {autoClose: 1000});
      return;
    }
    setSendingData(true);
    await postOffer(body)
      .then(async (data) => {
        toast.success('Offer created successfully.', {autoClose: 1000});
        await uploadImage(data.id, imageFile);
        navigate('/offers');
      })
      .catch((error) => handlePostOfferError(error))
      .finally(() => {
        setSendingData(false);
        setShowAddModal(false);
      });
  }

  /**
   * Returns object of new offer request body
   * @returns new offer request body
   */
  function getUploadOfferBody(): CreateOfferRequest {
    return {
      name: name,
      client: user.client,
      description: description,
      category: category,
      image_name: imageName,
      wage: {
        timeunit: wageTimeUnit ? (wageTimeUnit as TimeUnit) : undefined,
        value: wageValue,
      },
      location: {...locationGeo},
      isRemote: isRemote,
      validFrom: validFrom,
    };
  }

  /**
   * Handle new offer request error response
   * @param error upload offer error response
   */
  function handlePostOfferError(error: any) {
    if (error.response.status === 500) {
      toast.error('Failed to create an offer');
    } else if (error.response.status === 422) {
      toast.error('Please fix all validation errors', {autoClose: 2000});
      setFieldsErrorMessages(error.response.data.detail);
      setActiveStep(0);
    }
  }

  /**
   * Upload image for new offer and attach it to offer
   * @param offerId offer id
   * @param image   image to be sent
   */
  async function uploadImage(offerId: string, image?: File) {
    if (!image) {
      return;
    }
    try {
      uploadOfferImageToS3(offerId, image).then(async (imageName) => {
        await putOfferImage(offerId, imageName);
        toast.success('Uploaded image successfully.', {autoClose: 1000});
      });
    } catch (err) {
      toast.error('Failed to send images.', {autoClose: 1000});
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent='center'>
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign='center'>
              <MDBox mb={1}>
                <MDTypography variant='h3' fontWeight='bold'>
                  Add New Offer
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {activeStep === 0 && (
                    <EditAddOfferForm
                      name={name}
                      setName={setName}
                      nameErrorMessage={returnErrorMessageByNames(
                        fieldsErrorMessages,
                        ['body', 'name'],
                      )}
                      description={description}
                      setDescription={setDescription}
                      descriptionErrorMessage={returnErrorMessageByNames(
                        fieldsErrorMessages,
                        ['body', 'description'],
                      )}
                      category={category}
                      setCategory={setCategory}
                      categoryErrorMessage={returnErrorMessageByNames(
                        fieldsErrorMessages,
                        ['body', 'category'],
                      )}
                      categoriesList={categories}
                      wageTimeUnit={wageTimeUnit}
                      setWageTimeUnit={setWageTimeUnit}
                      wageTimeUnitErrorMessage={returnErrorMessageByNames(
                        fieldsErrorMessages,
                        ['body', 'wage', 'timeunit'],
                      )}
                      wageValue={wageValue}
                      setWageValue={setWageValue}
                      wageValueErrorMessage={returnErrorMessageByNames(
                        fieldsErrorMessages,
                        ['body', 'wage', 'value'],
                      )}
                      locationCity={locationCity}
                      setLocationCity={setLocationCity}
                      locationCityErrorMessage={returnErrorMessageByNames(
                        fieldsErrorMessages,
                        ['body', 'location', 'city'],
                      )}
                      locationErrorMessage={returnErrorMessageByNames(
                        fieldsErrorMessages,
                        ['body', 'location'],
                      )}
                      locationsList={locations}
                      locationGeo={locationGeo}
                      setLocationGeo={setLocationGeo}
                      isRemote={isRemote}
                      setIsRemote={setIsRemote}
                      validFrom={validFrom}
                      setValidFrom={setValidFrom}
                      validFromErrorMessage={returnErrorMessageByNames(
                        fieldsErrorMessages,
                        ['body', 'validFrom'],
                      )}
                    />
                  )}
                  {activeStep === 1 && (
                    <AddOfferImage setDropzoneInstance={setDropzoneInstance} />
                  )}
                  <MDBox
                    p={2}
                    width='100%'
                    display='flex'
                    justifyContent='space-between'>
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton
                        variant='gradient'
                        color='light'
                        onClick={handleBack}>
                        back
                      </MDButton>
                    )}
                    <MDButton
                      variant='gradient'
                      color='dark'
                      onClick={!isLastStep ? handleNext : showNewOfferModal}>
                      {isLastStep ? 'send offer' : 'next'}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <InfoDialog
        title='New offer'
        text='Add new offer?'
        visibility={showAddModal}
        closeFunction={() => {
          setShowAddModal(false);
        }}
        actionFunction={uploadOffer}
        actionText='add offer'
      />
    </DashboardLayout>
  );
}

export default AddOffer;

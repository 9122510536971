/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';

// Settings page components
import BaseLayout from 'pages/settings/components/BaseLayout';
import Sidenav from 'pages/settings/components/Sidenav';
import Header from 'pages/settings/components/Header';
import ChangePassword from 'pages/settings/components/ChangePassword';

// Data redux
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'redux/store';
import ChangeImages from './components/ChangeImages';
import {updateEmployer} from 'redux/reducers/employerSlice';
import {Loader} from '../../components/Loader';
import {CompanySize} from '../../enums/CompanySize';
import MDTypography from '../../components/MDTypography';
import FormField from '../../forms/controls/FormField';
import {FieldDescription} from '../../forms/controls/FieldDescription';
import Description from '../../forms/controls/Description';
import Autocomplete from '@mui/material/Autocomplete';
import MDInput from '../../components/MDInput';
import MDButton from '../../components/MDButton';
import Card from '@mui/material/Card';
import {useEffect, useState} from 'react';
import {EmployerDTO} from '../../dtos/EmployerDTO';

/**
 * Component for displaying profile settings;
 * for profile update
 * @returns
 */
function Settings(): JSX.Element {
  // redux
  const {employer, status: employerStatus} = useSelector(
    (state: RootState) => state.employer,
  );

  const [updatedEmployer, setUpdatedEmployer] = useState<
    EmployerDTO | undefined
  >(employer ? {...employer} : undefined);

  const companySizeList = [
    CompanySize.SMALL,
    CompanySize.MEDIUM,
    CompanySize.BIG,
  ];

  // state - components switch
  const [activeComponent, setActiveComponent] = useState('company-info');

  const dispatch = useDispatch();

  useEffect(() => {
    if (employer && !updatedEmployer) {
      setUpdatedEmployer({...employer});
    }
  }, [employer]);
  /**
   * Update employer object function
   */
  function updateEmployerFunction() {
    if (updatedEmployer) {
      dispatch(
        updateEmployer({id: updatedEmployer.uuid, body: updatedEmployer}),
      );
    }
  }

  const settingsPage = (settingsPageContents: JSX.Element): JSX.Element => {
    return (
      <BaseLayout>
        <MDBox mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Sidenav setActiveComponent={setActiveComponent} />
            </Grid>
            <Grid item xs={12} lg={9}>
              <MDBox mb={3}>{settingsPageContents}</MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </BaseLayout>
    );
  };

  if (employerStatus === 'failed' || employerStatus === 'notFound') {
    return settingsPage(
      <MDTypography variant='h6' fontWeight='medium' textTransform='capitalize'>
        failed to load employer data
      </MDTypography>,
    );
  }

  if (
    !updatedEmployer ||
    !employer ||
    employerStatus === 'loading' ||
    employerStatus === 'initial'
  ) {
    return settingsPage(<Loader label={'Loading employer data'} />);
  }

  return settingsPage(
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Header
          companyName={employer ? employer.name : ''}
          profileImage={employer ? employer.profileImage : ''}
        />
      </Grid>
      {activeComponent === 'company-info' && (
        <Grid item xs={12}>
          <Card id='company-info' sx={{overflow: 'visible'}}>
            <MDBox p={3}>
              <MDTypography variant='h5'>Company Info</MDTypography>
            </MDBox>
            <MDBox component='form' pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormField
                    label='Company Name'
                    defaultValue={updatedEmployer?.name || ''}
                    onChange={(e: any) => {
                      setUpdatedEmployer({
                        ...updatedEmployer,
                        name: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FieldDescription name='Description' />
                  <Description
                    description={updatedEmployer?.description || ''}
                    setDescription={(newDescription) => {
                      setUpdatedEmployer({
                        ...updatedEmployer,
                        description: newDescription,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FieldDescription name='Company Size' />
                  <Autocomplete
                    value={updatedEmployer?.companySize || CompanySize.MEDIUM}
                    options={companySizeList}
                    onInputChange={(event, newInputValue) => {
                      setUpdatedEmployer({
                        ...updatedEmployer,
                        companySize: newInputValue as CompanySize,
                      });
                    }}
                    renderInput={(params) => (
                      <MDInput {...params} variant='standard' />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    label='Primary sector'
                    defaultValue={updatedEmployer?.primarySector || ''}
                    onChange={(e: any) => {
                      setUpdatedEmployer({
                        ...updatedEmployer,
                        primarySector: updatedEmployer.primarySector,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} dir='rtl'>
                  <MDButton
                    variant='gradient'
                    color='dark'
                    size='small'
                    onClick={updateEmployerFunction}>
                    update company info
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      )}
      {activeComponent === 'change-password' && (
        <Grid item xs={12}>
          <ChangePassword />
        </Grid>
      )}
      {activeComponent === 'change-images' && (
        <Grid item xs={12}>
          <ChangeImages />
        </Grid>
      )}
    </Grid>,
  );
}

export default Settings;

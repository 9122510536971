/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Card from '@mui/material/Card';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Images
import bgImage from 'assets/images/bg-reset-cover.jpeg';
import BasicLayout from '../components/BasicLayout';
import {info} from '../../../utils/logging';
import {Link} from 'react-router-dom';
import {ResetPasswordForm} from '../components/ResetPasswordForm';

export default function ResetPasswordPage(): JSX.Element {
  info('entered reset password page');
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant='gradient'
          bgColor='info'
          borderRadius='lg'
          coloredShadow='success'
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign='center'>
          <MDTypography variant='h3' fontWeight='medium' color='white' mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display='block' variant='button' color='white' my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <ResetPasswordForm />
          <MDBox mt={3} textAlign='center'>
            <MDTypography variant='button' color='text'>
              <MDTypography
                component={Link}
                to='/auth/login'
                variant='button'
                color='info'
                fontWeight='medium'
                textGradient>
                Return to login page
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

import {Bars} from 'react-loader-spinner';
import colors from '../assets/theme/base/colors';
import Grid from '@mui/material/Grid';
import MDTypography from './MDTypography';

interface LoaderProps {
  height?: number;
  width?: number;
  label?: string;
}

export function Loader({label, height, width}: LoaderProps): JSX.Element {
  return (
    <Grid
      container
      direction={'column'}
      justifyContent='center'
      alignItems='center'
      spacing={2}>
      <Grid item>
        <Bars
          height={height || 50}
          width={width || 50}
          color={colors.info.main}
          ariaLabel='mutating-dots-loading'
          visible={true}
        />
      </Grid>
      {label && (
        <Grid item>
          <MDTypography variant='button' fontWeight='regular' color='text'>
            {label}
          </MDTypography>
        </Grid>
      )}
    </Grid>
  );
}

import {configureStore} from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist';
import thunk from 'redux-thunk';

import offersReducer from './reducers/offersSlice';
import locationsReducer from './reducers/locationsSlice';
import categoriesReducer from './reducers/categoriesSlice';
import employerReducer from './reducers/employerSlice';
import loginReducer from './reducers/loginSlice';
import snackbarReducer from './reducers/snackbarSlice';
import settingsReducer from './reducers/settingsSlice';

const loginPersistConfig = {
  key: 'login',
  storage,
};

const persistedLoginReducer = persistReducer(loginPersistConfig, loginReducer);

export const store = configureStore({
  reducer: {
    offers: offersReducer,
    locations: locationsReducer,
    categories: categoriesReducer,
    login: persistedLoginReducer,
    snackbar: snackbarReducer,
    employer: employerReducer,
    settings: settingsReducer,
  },

  middleware: [thunk],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

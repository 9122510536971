/**
 * Format date and time;
 * output format: locale date format | locale time format
 * @param string date-time string
 * @returns formatted string
 */
export function formatDate(string: string): string {
  const date = new Date(string);
  return (
    date.toLocaleDateString() + ' | ' + date.toLocaleTimeString().slice(0, 5)
  );
}

import {Page} from './Page';
import Grid from '@mui/material/Grid';
import MDTypography from '../../components/MDTypography';
import {useMemo} from 'react';
import {Loader} from '../../components/Loader';

interface PageLoadingProps {
  label?: string;
}

export function PageLoading({label}: PageLoadingProps): JSX.Element {
  const headerText = useMemo(() => {
    return label ? label : 'Loading data.';
  }, [label]);
  return (
    <Page>
      <Grid
        container
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'>
        <Grid item xs={4} sm={3} md={2}>
          <Loader />
        </Grid>
        <Grid item>
          <MDTypography variant='caption' color='text' fontWeight='regular'>
            {headerText}
          </MDTypography>
        </Grid>
      </Grid>
    </Page>
  );
}

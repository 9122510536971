import {createSlice} from '@reduxjs/toolkit';

type SettingsInitialState = {
  // access token is expired or invalid - api returns AuthError
  tokenNotValid: boolean;
};

const initialState: SettingsInitialState = {
  tokenNotValid: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    tokenExpired: (state) => {
      state.tokenNotValid = true;
    },
    tokenValid: (state) => {
      state.tokenNotValid = false;
    },
  },
});

export default settingsSlice.reducer;
export const {tokenExpired, tokenValid} = settingsSlice.actions;

/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import {useState} from 'react';
import {changePassword, getUser} from 'client/AppClient';
import {UpdatePasswordRequest} from 'client/models/UpdatePasswordRequest';
import {Alert, AlertColor} from '@mui/material';

function ChangePassword(): JSX.Element {
  const errorColor: AlertColor = 'error';
  const successColor: AlertColor = 'success';

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmed, setNewPasswordConfirmed] = useState('');

  const [responseMessage, setResponseMessage] = useState('');
  const [messageSeverity, setMessageSeverity] = useState(null);
  const [showResponseMessage, setShowResponseMessage] = useState(false);

  function updatePasswordFunction() {
    if (newPassword === newPasswordConfirmed) {
      getUser()
        .then((data) => {
          const id = data.id;
          changePassword(id, body)
            .then(() => {
              setResponseMessage('Password changed.');
              setMessageSeverity(successColor);
              setShowResponseMessage(true);
            })
            .catch((error) => {
              setResponseMessage(error.message);
              setMessageSeverity(errorColor);
              setShowResponseMessage(true);
            });
        })
        .catch((error) => {
          setResponseMessage(error.message);
          setMessageSeverity(errorColor);
          setShowResponseMessage(true);
        });
      const body: UpdatePasswordRequest = {password: newPassword};
    } else {
      setResponseMessage('New password does not match confirmed password.');
      setMessageSeverity(errorColor);
      setShowResponseMessage(true);
    }
  }

  return (
    <Card id='change-password'>
      <MDBox p={3}>
        <MDTypography variant='h5'>Change Password</MDTypography>
      </MDBox>
      <MDBox component='form' pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label='New Password'
              inputProps={{type: 'password', autoComplete: ''}}
              defaultValue={newPassword}
              onChange={(e: any) => {
                setNewPassword(e.target.value);
              }}
              onClick={() => {
                setShowResponseMessage(false);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label='Confirm New Password'
              inputProps={{type: 'password', autoComplete: ''}}
              defaultValue={newPasswordConfirmed}
              onChange={(e: any) => {
                setNewPasswordConfirmed(e.target.value);
              }}
              onClick={() => {
                setShowResponseMessage(false);
              }}
            />
          </Grid>
          {showResponseMessage && (
            <Grid item xs={12}>
              <Alert severity={messageSeverity}>{responseMessage}</Alert>
            </Grid>
          )}
          <Grid item xs={12} dir='rtl'>
            <MDButton
              variant='gradient'
              color='dark'
              size='small'
              onClick={updatePasswordFunction}>
              update password
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default ChangePassword;

import {useMemo} from 'react';
import {CURRENT_CONFIG} from '../config';

interface RelativeTimeProps {
  timestamp: string;
}
export default function RelativeTime(props: RelativeTimeProps): JSX.Element {
  const currentDate = useMemo(() => new Date(), [props.timestamp]);
  const previous: Date | null = useMemo(
    () => (props.timestamp ? new Date(props.timestamp) : null),
    [props.timestamp],
  );

  const getRelativeTime = (): string => {
    if (!previous) {
      return '-';
    }
    const relativeTimeFormat = new Intl.RelativeTimeFormat(
      CURRENT_CONFIG.defaultLanguage,
      {numeric: 'auto'},
    );

    const elapsed = currentDate.getTime() - previous.getTime();
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerMonth * 12;

    if (elapsed < msPerMinute) {
      return relativeTimeFormat.format(-Math.round(elapsed / 1000), 'second');
    } else if (elapsed < msPerHour) {
      return relativeTimeFormat.format(
        -Math.round(elapsed / msPerMinute),
        'minute',
      );
    } else if (elapsed < msPerDay) {
      return relativeTimeFormat.format(
        -Math.round(elapsed / msPerHour),
        'hour',
      );
    } else if (elapsed < msPerMonth) {
      return relativeTimeFormat.format(-Math.round(elapsed / msPerDay), 'day');
    } else if (elapsed < msPerYear) {
      return relativeTimeFormat.format(
        -Math.round(elapsed / msPerMonth),
        'month',
      );
    } else {
      return relativeTimeFormat.format(
        -Math.round(elapsed / msPerYear),
        'year',
      );
    }
  };
  return <p>{getRelativeTime()}</p>;
}

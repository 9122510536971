/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useState, useEffect} from 'react';

// react-router components
import {useLocation, Link} from 'react-router-dom';

// @material-ui core components
import {AppBar, Toolbar, IconButton, Icon, Menu, MenuItem} from '@mui/material';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React TS examples components
import Breadcrumbs from 'examples/Breadcrumbs';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
  ThemeSwitch,
} from 'examples/Navbars/DashboardNavbar/styles';

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setDarkMode,
} from 'context';

import {languages} from 'i18n/config';
import {useTranslation} from 'react-i18next';

// Declaring prop types for DashboardNavbar
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
  customTitle?: string;
}

function DashboardNavbar({
  absolute,
  light,
  isMini,
  customTitle,
}: Props): JSX.Element {
  const [navbarType, setNavbarType] = useState<
    'fixed' | 'absolute' | 'relative' | 'static' | 'sticky'
  >();
  const [controller, dispatch] = useMaterialUIController();
  const {miniSidenav, transparentNavbar, fixedNavbar, darkMode} = controller;
  const route = useLocation().pathname.split('/').slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar,
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  // Language switcher
  const {i18n} = useTranslation();
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const handleOpenLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setLanguageMenuAnchorEl(event.currentTarget);
  };
  const handleCloseLanguageMenu = (selectedLanguage?: string) => {
    setLanguageMenuAnchorEl(null);
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  };

  // Theme switcher
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  const renderLanguageMenu = () => (
    <Menu
      id='language-menu'
      aria-labelledby='language-menu'
      anchorEl={languageMenuAnchorEl}
      open={Boolean(languageMenuAnchorEl)}
      onClose={handleCloseLanguageMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{mt: 2}}>
      {languages.map((item) => (
        <MenuItem
          key={item.key}
          onClick={() => handleCloseLanguageMenu(item.key)}
          selected={i18n.language === item.key}>
          {`${item.flag} ${item.name}`}
        </MenuItem>
      ))}
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: {dark, white, text},
    functions: {rgba},
  }: {
    palette: any;
    functions: any;
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color='inherit'
      sx={(theme) =>
        navbar(theme, {transparentNavbar, absolute, light, darkMode})
      }>
      <Toolbar sx={navbarContainer}>
        <MDBox
          color='inherit'
          mb={{xs: 1, md: 0}}
          sx={(theme) => navbarRow(theme, {isMini})}>
          <IconButton
            sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
            size='small'
            disableRipple>
            <Icon fontSize='medium' sx={iconsStyle}>
              {miniSidenav ? 'menu_open' : 'menu'}
            </Icon>
          </IconButton>
          {!miniSidenav && <MDBox px={1}></MDBox>}
          <Breadcrumbs
            icon='home'
            title={route[route.length - 1]}
            customTitle={customTitle}
            route={route}
            light={light}
          />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, {isMini})}>
            <MDBox color={light ? 'white' : 'inherit'}>
              <Link to={'/profile'}>
                <IconButton sx={navbarIconButton} size='small' disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>
              <IconButton
                size='small'
                disableRipple
                color='inherit'
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}>
                <Icon sx={iconsStyle} fontSize='medium'>
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
              {/* <IconButton
                size='small'
                color='inherit'
                sx={navbarIconButton}
                onClick={handleOpenLanguageMenu}>
                <Icon sx={iconsStyle}>flag</Icon>
              </IconButton> */}
              <ThemeSwitch checked={darkMode} onChange={handleDarkMode} />
              {/* {renderLanguageMenu()} */}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

export default DashboardNavbar;

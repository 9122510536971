/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {Dispatch, SetStateAction, useMemo, useState} from 'react';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import OfferDropzone from '../OfferDropzone';
import Dropzone from 'dropzone';
import MDAlert from '../../../../../components/MDAlert';
import MDSnackbar from '../../../../../components/MDSnackbar';

// Declaring props types
interface Props {
  setDropzoneInstance: Dispatch<SetStateAction<Dropzone>>;
}

/**
 * Component for updating offer image
 * @param param0 setDropzoneInstance function setting dropzone instance in parent component
 * @returns
 */
function EditOfferImage({setDropzoneInstance}: Props): JSX.Element {
  const maxFiles = 2;
  const [maxImagesNotification, setImagesNotification] =
    useState<boolean>(false);
  return (
    <MDBox p={2}>
      <MDSnackbar
        color='warning'
        icon='warning'
        title='Number of images'
        dateTime={'Now'}
        content={`You can upload up to ${maxFiles} images.`}
        open={maxImagesNotification}
        close={() => setImagesNotification(false)}
        bgWhite
      />
      <MDTypography variant='h5'>Offer Image</MDTypography>
      <MDBox mt={3}>
        {useMemo(
          () => (
            <OfferDropzone
              options={{
                addRemoveLinks: true,
                multiple: false,
                maxFiles: maxFiles,
                maxFilesize: 4 * 1024 * 1024, // 4MB
                maxfilesexceeded: function () {
                  // remove last file added
                  this.removeFile(this.files[this.files.length - 1]);
                  setImagesNotification(true);
                },
              }}
              setDropzoneInstance={setDropzoneInstance}
            />
          ),
          [setDropzoneInstance],
        )}
      </MDBox>
    </MDBox>
  );
}

export default EditOfferImage;

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import {LocationOfferDTO} from 'dtos/LocationOfferDTO';

/**
 * Component for displaying location info from offer
 * @param props location object
 * @returns
 */
function LocationInfo(props: {location: LocationOfferDTO}): JSX.Element {
  return (
    <MDBox
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      mb={1}>
      <MDBox>
        <MDTypography variant='h6' fontWeight='medium' mb={1}>
          Location
        </MDTypography>
        {props.location.city && (
          <MDBox>
            <MDTypography
              component='p'
              variant='button'
              color='text'
              textAlign='justify'>
              City: <b>{props.location.city}</b>
            </MDTypography>
          </MDBox>
        )}
        {props.location.formattedAddress && (
          <MDBox>
            <MDTypography
              component='p'
              variant='button'
              color='text'
              textAlign='justify'>
              Address: <b>{props.location.formattedAddress}</b>
            </MDTypography>
          </MDBox>
        )}
        {props.location.perimeter && (
          <MDBox>
            <MDTypography
              component='p'
              variant='button'
              color='text'
              textAlign='justify'>
              Perimeter: <b>{props.location.perimeter}</b>
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}

export default LocationInfo;

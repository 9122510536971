/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState} from 'react';

// react-router-dom components
import {Link, Navigate} from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Authentication layout components
import BasicLayout from 'pages/authentication/components/BasicLayout';

// Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg';

// Data redux
import {useSelector} from 'react-redux';
import {RootState} from 'redux/store';

// Api call
import {Loader} from '../../../components/Loader';
import {LoginForm} from '../components/LoginForm';

function SignInPage(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {token} = useSelector((state: RootState) => state.login);

  const getSigningComponent = (): JSX.Element => {
    return (
      <>
        <MDBox
          variant='gradient'
          bgColor='info'
          borderRadius='lg'
          coloredShadow='info'
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign='center'>
          <MDTypography variant='h4' fontWeight='medium' color='white' mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <LoginForm setIsLoading={setIsLoading} />
          <MDBox mt={3} textAlign='center'>
            <MDTypography variant='button' color='text'>
              Forgot the password?{' '}
              <MDTypography
                component={Link}
                to='/auth/reset-password'
                variant='button'
                color='info'
                fontWeight='medium'
                textGradient>
                Reset password
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </>
    );
  };

  if (token) {
    // navigate to home if logged in (if there is a token)
    return <Navigate to={'/offers'} />;
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>{isLoading ? <Loader /> : getSigningComponent()}</Card>
    </BasicLayout>
  );
}

export default SignInPage;

import {Alert, Autocomplete} from '@mui/material';
import MDBox from 'components/MDBox';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import FormField from '../FormField';

interface Props {
  label?: string;
  value: any;
  setValue: Dispatch<SetStateAction<string>>;
  optionsList: any[];
  listKey: string;
  required?: boolean;
  freeSolo?: boolean;
  errorMessage?: string;
}

/**
 * Autocomplete component;
 * options given by list of objects;
 * listKey param defines property from object displayed in autocomplete list
 * @param param0 autocomplete props
 * @returns
 */
function AutocompleteObjectsList({
  label,
  value,
  setValue,
  optionsList,
  listKey,
  required,
  errorMessage,
  freeSolo,
}: Props): JSX.Element {
  const [message, setMessage] = useState('');
  useEffect(() => {
    setMessage(errorMessage);
  }, [errorMessage]);

  return (
    <MDBox>
      <Autocomplete
        inputValue={value}
        options={optionsList.map((element: any) => {
          return element[listKey];
        })}
        freeSolo={freeSolo}
        onInputChange={(event, newInputValue) => {
          setValue(newInputValue);
        }}
        renderInput={(params) => (
          <FormField
            {...params}
            label={label}
            required={required}
            error={message}
            onClick={() => {
              setMessage('');
            }}
            setValue={setValue}
            InputLabelProps={{shrink: true}}
          />
        )}
      />
      {message && <Alert severity='error'>{message}</Alert>}
    </MDBox>
  );
}

// Declaring default props
AutocompleteObjectsList.defaultProps = {
  label: ' ',
  required: false,
  error: false,
  freeSolo: false,
};

export default AutocompleteObjectsList;

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getLocations} from 'client/AppClient';
import {LocationDTO} from 'dtos/LocationDTO';
import {AsyncDataStatus} from '../utils';

type LocationsInitialState = {
  locations?: LocationDTO[];
  status: AsyncDataStatus;
};

const initialState: LocationsInitialState = {
  locations: undefined,
  status: 'initial',
};

/**
 * Get locations list from API
 */
export const fetchLocations = createAsyncThunk(
  'locations/getLocations',
  async () => {
    return await getLocations();
  },
);

const locationsSlice = createSlice({
  name: 'locations',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.locations = undefined;
        state.status = 'loading';
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.locations = action.payload;
        state.status = 'loaded';
      })
      .addCase(fetchLocations.rejected, (state) => {
        state.locations = undefined;
        state.status = 'failed';
      });
  },
});

export default locationsSlice.reducer;

/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/* eslint-disable react/prop-types */
// ProductsList page components
import NavigableCell from '../../../../components/table/NavigableCell';
import {OfferDTO} from '../../../../dtos/OfferDTO';
import {DateCell} from '../../../../components/table/DateCell';

/**
 * Offers list table header definition
 */
const dataTableHeader = [
  {
    // Name column definition
    Header: 'name',
    accessor: 'name',
    Cell: ({row}: any) => {
      const offer = row.original as OfferDTO;
      return (
        <NavigableCell
          value={offer.name}
          route={`/offer/details/${offer.id}`}
        />
      );
    },
    // for non-default sorting: https://react-table-v7.tanstack.com/docs/api/useSortBy#table-options
    // sortType: sortStringValues,
  },
  {
    // Category column definition
    Header: 'category',
    accessor: 'category',
  },
  {
    // City column definition
    Header: 'city',
    accessor: 'city',
  },
  {
    // Last Modified column definition
    Header: 'modified',
    accessor: 'modified',
    Cell: ({value}: any): JSX.Element => {
      return <DateCell timestamp={value} />;
    },
  },
  {
    // Valid From column definition
    Header: 'valid From',
    accessor: 'validFrom',
    Cell: ({value}: any): JSX.Element => {
      return <DateCell timestamp={value} />;
    },
  },
];

export default dataTableHeader;

import {createSlice} from '@reduxjs/toolkit';

const initialState: {
  color:
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'dark'
    | 'light';
  icon: string;
  title: string;
  content: string;
  dateTime: string;
  open: boolean;
} = {
  color: 'info',
  icon: 'notifications',
  title: 'SwipeApp',
  content: 'Hello, world! This is a notification message',
  dateTime: '',
  open: false,
};

/**
 * Snackbar's data for displaying
 */

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: initialState,
  reducers: {
    setSnackbarContent: (state, action) => {
      state.content = action.payload;
      state.open = true;
    },
    toggleSnackbar: (state) => {
      state.open = !state.open;
    },
  },
});

export default snackbarSlice.reducer;
export const {setSnackbarContent, toggleSnackbar} = snackbarSlice.actions;

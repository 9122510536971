/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';

// Overview page components
import EmployerHeader from 'pages/profile/components/Header/EmployerHeader';

// Data redux
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'redux/store';
import CompanyProfileInfo from './components/CompanyProfileInfo';
import {Page} from '../common/Page';
import {PageLoadFailed} from '../common/PageLoadFailed';
import {fetchEmployerById} from '../../redux/reducers/employerSlice';
import {PageLoading} from '../common/PageLoading';

/**
 * Component for displaying user profile
 * @returns
 */
function EmployerProfile(): JSX.Element {
  const {user} = useSelector((state: RootState) => state.login);
  // redux
  const {employer, status: employerStatus} = useSelector(
    (state: RootState) => state.employer,
  );

  const dispatch = useDispatch();
  const reloadEmployer = () => {
    if (user?.employer_id) {
      dispatch(fetchEmployerById(user.employer_id));
    }
  };

  if (employerStatus === 'failed') {
    return (
      <PageLoadFailed
        label={"Failed to load employer's profile"}
        onReload={reloadEmployer}
      />
    );
  } else if (employerStatus === 'notFound') {
    return (
      <PageLoadFailed
        label={`Employer with id ${user?.employer_id} not found.`}
      />
    );
  } else if (
    !employer ||
    employerStatus === 'loading' ||
    employerStatus === 'initial'
  ) {
    return <PageLoading label={'Loading employer'} />;
  }

  return (
    <Page>
      <EmployerHeader employer={employer}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item>
              <CompanyProfileInfo employerData={employer} />
            </Grid>
          </Grid>
        </MDBox>
      </EmployerHeader>
    </Page>
  );
}

export default EmployerProfile;

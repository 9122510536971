/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Dropzone components
import Dropzone from 'dropzone';

// Dropzone styles
import 'dropzone/dist/dropzone.css';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';

// Custom styles for the MDDropzone
import SAMDDropzoneRoot from './DropzoneRoot';

// Material Dashboard 2 PRO React context
import {useMaterialUIController} from 'context';

import {useState} from 'react';
import {RootState} from 'redux/store';

import {putEmployerImage} from 'client/AppClient';
import {EmployerImageType} from 'enums/EmployerImageType';

import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import MDButton from 'components/MDButton';
import {uploadEmployerImageToS3} from 'api/uploadEmployerImage';
import {Alert, AlertColor} from '@mui/material';
import {fetchEmployerById} from '../../../../../redux/reducers/employerSlice';
import {PageLoadFailed} from '../../../../common/PageLoadFailed';
import {Loader} from '../../../../../components/Loader';

const errorColor: AlertColor = 'error';
const successColor: AlertColor = 'success';

// Declaring props types for MDDropzone
interface Props {
  options: {
    [key: string | number]: any;
  };
  employerImageType: EmployerImageType;
}

function EmployerImageDropzone({
  options,
  employerImageType,
}: Props): JSX.Element {
  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  // redux
  const {employer} = useSelector((state: RootState) => state.employer);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [messageSeverity, setMessageSeverity] = useState(errorColor);

  const dispatch = useDispatch();

  async function handleSubmit() {
    if (!employer) {
      return;
    }
    setIsLoading(true);
    try {
      const file: File = dropzoneInstance.files[0];

      if (dropzoneInstance.files[0]) {
        const objectUUID = await uploadEmployerImageToS3(
          employer.uuid,
          file,
          employerImageType,
        );
        await putEmployerImage(employer.uuid, {
          images: [objectUUID],
          imagesType: employerImageType,
        });
        setMessageSeverity(successColor);
        setMessage('Image saved.');
        // update local employer state
        dispatch(fetchEmployerById(employer.uuid));
      } else {
        setMessageSeverity(errorColor);
        setMessage('No image to send.');
      }
    } catch (err) {
      console.log('error is', err);
      setMessageSeverity(errorColor);
      setMessage('Image not saved.');
    } finally {
      setIsLoading(false);
    }
  }

  function clearOnClick() {
    setMessage('');
  }

  Dropzone.autoDiscover = false;

  const formHTML = document.createElement('form');
  const dropzoneRef = useRef<HTMLFormElement | null>(formHTML);
  const [dropzoneInstance, setDropzoneInstance] = useState(null);

  useEffect(() => {
    function createDropzone() {
      return new Dropzone(dropzoneRef.current, {...options});
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0)
        Dropzone.instances.forEach((dz: any) => dz.destroy());
    }

    setDropzoneInstance(createDropzone());

    return () => removeDropzone();
  }, [options]);

  if (!employer) {
    return <PageLoadFailed label={'Failed to load employer.'} />;
  }

  if (isLoading) {
    return <Loader label={'Uploading image'} />;
  }

  return (
    <>
      <SAMDDropzoneRoot
        action='#'
        onClick={clearOnClick}
        ref={dropzoneRef}
        className='form-control dropzone'
        ownerState={{darkMode}}>
        <MDBox className='fallback' bgColor='transparent'>
          <input
            name='profile-picture'
            type='file'
            accept='image/*'
            id='profile-picture'
            className='hidden'
          />
        </MDBox>
      </SAMDDropzoneRoot>
      {message && <Alert severity={messageSeverity}>{message}</Alert>}
      <MDBox mt={2} dir='rtl'>
        <MDButton
          variant='gradient'
          color='dark'
          size='small'
          onClick={handleSubmit}>
          Upload image
        </MDButton>
      </MDBox>
    </>
  );
}

export default EmployerImageDropzone;

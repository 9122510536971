/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import parse from 'html-react-parser';

/**
 * Component for displaying formatted description
 * @param props description string
 * @returns
 */
export default function DescriptionWithHTML(props: {
  description: string;
}): JSX.Element {
  const style = {
    fontSize: 13,
    listStylePosition: 'inside',
  };

  /**
   * Create formatted description
   * @returns description for displaying
   */
  function renderDescription() {
    return (
      <MDBox>
        <MDTypography style={style} component={'span'}>
          {parse(props.description)}
        </MDTypography>
      </MDBox>
    );
  }

  return <MDBox my={1}>{renderDescription()}</MDBox>;
}

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

interface IFieldDescription {
  name: string;
}

/**
 * Field description component - formated label for form field
 * @param param0 name - field name
 * @returns
 */
export function FieldDescription({name}: IFieldDescription): JSX.Element {
  return (
    <MDBox mb={2} display='inline-block'>
      <MDTypography
        component='label'
        variant='button'
        fontWeight='regular'
        color='text'
        textTransform='capitalize'>
        {name}
      </MDTypography>
    </MDBox>
  );
}

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useLocation, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import {Card} from '@mui/material';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

// Other components
import {InfoDialog} from 'dialogs/InfoDialog';
import {ErrorDialog} from 'dialogs/ErrorDialog';
import EditAddOfferForm from 'forms/components/EditAddOfferForm';
import EditOfferImage from './components/EditOfferImage';

// Data redux
import {useDispatch, useSelector} from 'react-redux';
import {fetchLocations} from 'redux/reducers/locationsSlice';
import {fetchCategories} from 'redux/reducers/categoriesSlice';
import {RootState} from 'redux/store';

// Api calls
import {UpdateOfferRequest} from 'client/models/UpdateOfferRequest';
import {putOffer, putOfferImage} from 'client/AppClient';
import {uploadOfferImageToS3} from 'api/uploadOfferImage';

import {TimeUnit} from 'enums/TimeUnit';
import {OfferDTO} from 'dtos/OfferDTO';
import {returnErrorMessageByNames} from 'functions/filterErrorMessages';
import {fetchOffers} from '../../../redux/reducers/offersSlice';
import {PageLoadFailed} from '../../common/PageLoadFailed';
import {PageLoading} from '../../common/PageLoading';
import {FieldValidationErrors} from '../../../types/validation';
import {validateUpdateOffer} from '../validateOffer';
import {toast} from 'react-toastify';

/**
 * Component for offer update
 * @returns
 */
function EditOffer(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {user} = useSelector((state: RootState) => state.login);

  type LocationState = {
    offer: OfferDTO;
  };

  const loc = useLocation();
  const state = loc.state as LocationState;
  const offer = state.offer;

  // Form State
  const [name, setName] = useState(offer.name);
  const [category, setCategory] = useState(offer.category);
  const [isRemote, setIsRemote] = useState(offer.isRemote);
  const [description, setDescription] = useState(offer.description);
  const [locationCity, setLocationCity] = useState(
    offer.location ? offer.location.city : '',
  );
  const [locationGeo, setLocationGeo] = useState(
    offer.location
      ? {
          city: offer.location.city,
          placeId: offer.location.placeId,
          geo: offer.location.geo,
          formattedAddress: offer.location.formattedAddress,
        }
      : undefined,
  );
  const [locationPerimeter, setLocationPerimeter] = useState(
    offer.location ? offer.location.perimeter : null,
  );
  const [wageTimeUnit, setWageTimeUnit] = useState(
    offer.wage ? offer.wage.timeunit : TimeUnit.HOUR,
  );
  const [wageValue, setWageValue] = useState(
    offer.wage ? offer.wage.value : null,
  );
  const [validFrom, setValidFrom] = useState(offer.validFrom);

  const [dropzoneInstance, setDropzoneInstance] = useState(null);

  // Fields Error Messages
  const [fieldsErrorMessages, setFieldsErrorMessages] =
    useState<FieldValidationErrors>([]);
  const [formErrorMessage, setFormErrorMessage] = useState('');

  // Dialogs
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const showUpdateOfferModal = () => {
    setShowUpdateModal(true);
  };
  const [showUpdateImageModal, setShowUpdateImageModal] = useState(false);
  const showUpdateOfferImageModal = () => {
    setShowUpdateImageModal(true);
  };
  const [showCancelUpdateModal, setShowCancelUpdateModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  // Redux
  const {locations, status: locationsStatus} = useSelector(
    (state: RootState) => state.locations,
  );
  const {categories, status: categoriesStatus} = useSelector(
    (state: RootState) => state.categories,
  );

  const refreshAll = () => {
    dispatch(fetchLocations());
    dispatch(fetchCategories());
  };

  useEffect(() => {
    if (locationsStatus === 'initial') {
      dispatch(fetchLocations());
    }
    if (categoriesStatus === 'initial') {
      dispatch(fetchCategories());
    }
  }, [dispatch, locationsStatus]);

  if (locationsStatus === 'failed' || categoriesStatus === 'failed') {
    return (
      <PageLoadFailed label={'Failed to load data'} onReload={refreshAll} />
    );
  }

  if (!categories || !locations) {
    return <PageLoading />;
  }

  /**
   * Update offer
   */
  async function updateOfferFunction() {
    const body: UpdateOfferRequest = getUpdateOfferBodyFunction();
    const validated = validateUpdateOffer(body);
    if (validated.length) {
      setFieldsErrorMessages([...validated]);
      toast.error('Please fix validation errors', {autoClose: 1000});
      return;
    }
    await putOffer(offer.id, body)
      .then((data) => handlePutOfferResponse(data))
      .catch((error) => handlePutOfferResponseError(error));
  }

  /**
   * Returns object of update offer request body
   * @returns update offer request body
   */
  function getUpdateOfferBodyFunction(): UpdateOfferRequest {
    return {
      name: name,
      client: offer.client,
      description: description,
      category: category,
      location: {...locationGeo},
      wage: {
        timeunit: wageTimeUnit ? (wageTimeUnit as TimeUnit) : undefined,
        value: wageValue,
      },
      image_name: offer.image,
      isRemote: isRemote,
      validFrom: validFrom,
      lastModified: offer.modified,
    };
  }

  /**
   * Handle upload update offer request response
   * @param data upload offer request response
   */
  function handlePutOfferResponse(data: any) {
    dispatch(fetchOffers(user.employer_id));
    navigate('/offers');
  }

  /**
   * Handle upload update offer error response
   * @param error upload offer error response
   */
  function handlePutOfferResponseError(error: any) {
    if (error.response.status === 500) {
      setFormErrorMessage(error.response.data.detail);
    } else if (error.response.status === 422) {
      setFormErrorMessage('Update failed.');
      setFieldsErrorMessages(error.response.data.detail);
    }
    setShowUpdateModal(false);
    setShowErrorModal(true);
  }

  /**
   * Update offer image
   */
  function updateOfferImageFunction() {
    if (dropzoneInstance.files.length !== 0) {
      uploadImage(offer.id);
    }
  }

  /**
   * Upload image for update offer and attach it to offer
   * @param offerId offer id
   */
  async function uploadImage(offerId: string) {
    try {
      uploadOfferImageToS3(offerId, dropzoneInstance.files[0]).then(
        (imageName) => {
          putOfferImage(offerId, imageName).then((data) => {
            navigate(`/offer/details/${offer.id}`);
          });
        },
      );
    } catch (err) {
      console.log('error is', err);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar customTitle={offer.name} />
      <MDBox mt={6} mb={5} textAlign='center'>
        <MDBox mb={1}>
          <MDTypography variant='h3' fontWeight='bold'>
            Edit Offer
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mt={4} my={6}>
        <Grid container spacing={3} justifyContent='center'>
          <Grid item xs={12} lg={8}>
            <Card id='company-info' sx={{overflow: 'visible'}}>
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <EditAddOfferForm
                      name={name}
                      setName={setName}
                      nameErrorMessage={returnErrorMessageByNames(
                        fieldsErrorMessages,
                        ['body', 'name'],
                      )}
                      description={description}
                      setDescription={setDescription}
                      descriptionErrorMessage={returnErrorMessageByNames(
                        fieldsErrorMessages,
                        ['body', 'description'],
                      )}
                      category={category}
                      setCategory={setCategory}
                      categoryErrorMessage={returnErrorMessageByNames(
                        fieldsErrorMessages,
                        ['body', 'category'],
                      )}
                      categoriesList={categories}
                      wageTimeUnit={wageTimeUnit}
                      setWageTimeUnit={setWageTimeUnit}
                      wageTimeUnitErrorMessage={returnErrorMessageByNames(
                        fieldsErrorMessages,
                        ['body', 'wage', 'timeunit'],
                      )}
                      wageValue={wageValue}
                      setWageValue={setWageValue}
                      wageValueErrorMessage={returnErrorMessageByNames(
                        fieldsErrorMessages,
                        ['body', 'wage', 'value'],
                      )}
                      locationCity={locationCity}
                      setLocationCity={setLocationCity}
                      locationCityErrorMessage={returnErrorMessageByNames(
                        fieldsErrorMessages,
                        ['body', 'location', 'city'],
                      )}
                      locationsList={locations}
                      locationGeo={locationGeo}
                      setLocationGeo={setLocationGeo}
                      isRemote={isRemote}
                      setIsRemote={setIsRemote}
                      validFrom={validFrom}
                      setValidFrom={setValidFrom}
                      validFromErrorMessage={returnErrorMessageByNames(
                        fieldsErrorMessages,
                        ['body', 'validFrom'],
                      )}
                    />
                    <MDBox
                      px={2}
                      width='100%'
                      display='flex'
                      justifyContent='space-between'>
                      <MDButton
                        variant='gradient'
                        color='light'
                        onClick={() => {
                          setShowCancelUpdateModal(true);
                        }}>
                        Cancel changes
                      </MDButton>
                      <MDButton
                        variant='gradient'
                        color='dark'
                        onClick={() => {
                          showUpdateOfferModal();
                        }}>
                        Save changes
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <EditOfferImage setDropzoneInstance={setDropzoneInstance} />
                    <MDBox
                      px={2}
                      width='100%'
                      display='flex'
                      justifyContent='space-between'>
                      <MDButton
                        variant='gradient'
                        color='light'
                        onClick={() => {
                          setShowCancelUpdateModal(true);
                        }}>
                        Cancel changes
                      </MDButton>
                      <MDButton
                        variant='gradient'
                        color='dark'
                        onClick={() => {
                          showUpdateOfferImageModal();
                        }}>
                        Save changes
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <InfoDialog
        title='Update Offer'
        text='Update offer?'
        visibility={showUpdateModal}
        closeFunction={() => {
          setShowUpdateModal(false);
        }}
        actionFunction={updateOfferFunction}
        actionText='update'
      />
      <InfoDialog
        title='Update Offer'
        text='Update offer?'
        visibility={showUpdateImageModal}
        closeFunction={() => {
          setShowUpdateImageModal(false);
        }}
        actionFunction={updateOfferImageFunction}
        actionText='update'
      />
      <InfoDialog
        title='Cancel Update Offer'
        text='Cancel update offer? All changes will be lost.'
        visibility={showCancelUpdateModal}
        closeFunction={() => {
          setShowCancelUpdateModal(false);
        }}
        actionFunction={() => {
          navigate('/offers');
        }}
        actionText='cancel update'
      />
      <ErrorDialog
        title='Error'
        errorText={formErrorMessage}
        visibility={showErrorModal}
        closeFunction={() => {
          setShowErrorModal(false);
        }}
      />
    </DashboardLayout>
  );
}

export default EditOffer;

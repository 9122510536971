import {AxiosInstance} from 'axios';
import {SuccessfulLoginResponse} from '../../dtos/AuthenticationDTO';

export class LoginService {
  constructor(private api: AxiosInstance) {}

  public async signIn(body: any): Promise<SuccessfulLoginResponse> {
    return this.api
      .post<SuccessfulLoginResponse>('/users/login', {
        username: body.username,
        password: body.password,
      })
      .then((response) => {
        return response.data;
      });
  }
}

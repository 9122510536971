/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useState, useEffect} from 'react';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable/index';

// // Data redux
import {getApplicationsForOffer} from 'client/AppClient';
import dataTableHeader from './data/dataTableHeader';
import {ApplicationsDTO} from '../../dtos/ApplicationDTO';
import {useLocation} from 'react-router-dom';

function ApplicationList(): JSX.Element {
  const [applications, setApplications] = useState<ApplicationsDTO>([]);
  const {pathname} = useLocation();
  const [offerId, setOfferId] = useState('');

  useEffect(() => {
    if (pathname) {
      setOfferId(pathname.split('/').at(-2));
    }
  }, [pathname]);

  useEffect(() => {
    if (offerId) {
      getApplicationsForOffer(offerId).then((data) => {
        setApplications(data);
      });
    }
  }, [offerId]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <DataTable
            table={{columns: dataTableHeader, rows: applications}}
            entriesPerPage={false}
            canSearch
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ApplicationList;

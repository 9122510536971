/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS components
import {Icon} from '@mui/material';
import MDTypography from 'components/MDTypography';

// Declaring props types for DefaultCell
interface Props {
  value: string;
  extension?: string;
  suffix?: string | boolean;
}

function OpenFileCell({value, extension}: Props): JSX.Element {
  return (
    <a
      download={`${value}.${extension}`}
      href={value}
      target='_blank'
      media='application/pdf'
      rel='noreferrer'>
      <MDTypography variant='caption' fontWeight='medium' color='text'>
        <span title={value}>
          Download <Icon sx={{fontWeight: 'bold'}}>download</Icon>
        </span>
      </MDTypography>
    </a>
  );
}

// Declaring default props for DefaultCell
OpenFileCell.defaultProps = {
  suffix: '',
};

export default OpenFileCell;

/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

// OfferDetails page components
import OfferInfo from './components/OfferInfo';
import Description from './components/Description';
import Conditional from './components/Conditional';
import Details from './components/Details';

// Redux
import {setSnackbarContent} from 'redux/reducers/snackbarSlice';

// Api call
import {deleteOffer, getOfferById} from 'client/AppClient';

// Dialogs
import {WarningDialog} from 'dialogs/WarningDialog';

// temp
import backgroundPlaceholderImage from 'assets/images/bg-profile.jpeg';
import {AsyncResourceStatus} from '../../../redux/utils';
import {PageLoading} from '../../common/PageLoading';
import {PageLoadFailed} from '../../common/PageLoadFailed';
import {AxiosError} from 'axios';
import {OfferDTO} from '../../../dtos/OfferDTO';
import {toast} from 'react-toastify';
import {error} from '../../../utils/logging';

function OfferDetails(): JSX.Element {
  const {id} = useParams();
  const [offer, setOffer] = useState<OfferDTO | undefined>(undefined);
  const [offerStatus, setOfferStatus] =
    useState<AsyncResourceStatus>('initial');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);

  const [offerImage, setOfferImage] = useState(offer ? offer.image : '');

  const navigate = useNavigate();

  const loadOffer = async (newId: string) => {
    setOfferStatus('loading');
    await getOfferById(newId)
      .then((response) => {
        setOffer(response);
        setOfferImage(response.image);
        setOfferStatus('loaded');
      })
      .catch((e: AxiosError) => {
        if (e.response.status === 400) {
          setOfferStatus('notFound');
        } else {
          setOfferStatus('failed');
        }
      });
  };

  useEffect(() => {
    loadOffer(id);
  }, [id]);

  async function deleteOfferFunction() {
    setDeleteInProgress(true);
    await deleteOffer(offer.id)
      .then(() => {
        toast.success('Offer deleted successfully.');
        navigate('/offers');
      })
      .catch((e: AxiosError) => {
        error(`Failed to delete offer with id ${offer.id}`, e.response.data);
        if (e.response.status === 400) {
          toast.error("Failed to delete offer - offer doesn't exists");
        } else {
          toast.error('Failed to delete offer');
        }
      })
      .finally(() => {
        setDeleteInProgress(false);
        setShowDeleteModal(false);
      });
  }

  if (offerStatus === 'failed') {
    return (
      <PageLoadFailed
        onReload={() => {
          loadOffer(id);
        }}
      />
    );
  } else if (offerStatus === 'notFound') {
    return <PageLoadFailed label={`Offer ${id} was not found.`} />;
  }
  if (!offer || offerStatus === 'loading') {
    return <PageLoading label={'Loading offer'} />;
  }

  if (deleteInProgress) {
    return <PageLoading label={'Removing the offer'} />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar customTitle={offer ? offer.name : null} />
      <MDBox my={6}>
        <Grid container spacing={3} justifyContent='center'>
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox pb={3} px={3} mt={3}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <OfferInfo offer={offer} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Description description={offer.description} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Conditional offer={offer} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Details
                      id={id}
                      modified={offer.modified}
                      validFrom={offer.validFrom}
                      client={offer.client}
                      companyId={offer.company_id}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <MDBox
                      display='flex'
                      justifyContent='space-between'
                      alignItems='flex-start'
                      mb={1}
                      mt={1}>
                      <MDButton
                        variant='gradient'
                        color='info'
                        onClick={() =>
                          navigate(`/offer/edit/${id}`, {
                            state: {offer: offer},
                          })
                        }>
                        Edit offer
                      </MDButton>
                      <MDButton
                        variant='gradient'
                        color='dark'
                        onClick={() =>
                          navigate(`/offer/details/${offer.id}/applications`, {
                            state: {'offer-id': offer.id},
                          })
                        }>
                        Show applications
                      </MDButton>
                      <MDButton
                        variant='gradient'
                        color='warning'
                        onClick={() => {
                          setShowDeleteModal(true);
                        }}>
                        Delete offer
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            <MDBox
              display='flex'
              alignItems='center'
              position='relative'
              minHeight='18.75rem'
              borderRadius='xl'
              sx={{
                backgroundSize: 'cover',
                backgroundPosition: '50%',
                overflow: 'hidden',
              }}
              mt={3}>
              <img
                src={offerImage}
                alt='not loaded'
                onError={() => setOfferImage(backgroundPlaceholderImage)}
                style={{width: '100%'}}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <WarningDialog
        title='Delete Offer'
        warningText='Delete this offer?'
        visibility={showDeleteModal}
        closeFunction={() => {
          setShowDeleteModal(false);
        }}
        actionFunction={deleteOfferFunction}
        actionText='delete'
      />
    </DashboardLayout>
  );
}

export default OfferDetails;

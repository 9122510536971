import {Alert, Autocomplete} from '@mui/material';
import MDBox from 'components/MDBox';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import FormField from '../FormField';

interface Props {
  label?: string;
  value: any;
  setValue: Dispatch<SetStateAction<string>>;
  options: string[];
  required?: boolean;
  errorMessage?: string;
  freeSolo?: boolean;
  disableClearable?: boolean;
  [key: string]: any;
}

/**
 * Autocomplete component;
 * options given by list of strings
 * @param param0 autocomplete props
 * @returns
 */
function AutocompleteStringsList({
  label,
  value,
  setValue,
  options,
  required,
  errorMessage,
  freeSolo,
  disableClearable,
  ...rest
}: Props): JSX.Element {
  const [message, setMessage] = useState('');
  useEffect(() => {
    setMessage(errorMessage);
  }, [errorMessage]);

  return (
    <MDBox>
      <Autocomplete
        value={value}
        options={options}
        freeSolo={freeSolo}
        onInputChange={(event, newInputValue) => {
          setValue(newInputValue);
        }}
        disableClearable={disableClearable}
        renderInput={(params) => (
          <FormField
            {...params}
            label={label}
            required={required}
            error={message}
            setValue={setValue}
            onClick={() => {
              setMessage('');
            }}
            InputLabelProps={{shrink: true}}
          />
        )}
      />
      {message && <Alert severity='error'>{message}</Alert>}
    </MDBox>
  );
}

// Declaring default props
AutocompleteStringsList.defaultProps = {
  label: ' ',
  required: false,
  error: false,
  freeSolo: false,
  disableClearable: false,
};

export default AutocompleteStringsList;

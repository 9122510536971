import {CreateOfferRequest} from './models/CreateOfferRequest';
import {UpdateEmployerRequest} from './models/UpdateEmployerRequest';
import {UpdateOfferRequest} from './models/UpdateOfferRequest';
import {LoginService} from './services/LoginService';
import {ApplicationService} from './services/ApplicationsService';
import {CategoriesService} from './services/CategoriesService';
import {EmployersService} from './services/EmployersService';
import {LocationsService} from './services/LocationsService';
import {OffersService} from './services/OffersService';
import {UserService} from './services/UserService';
import {UpdatePasswordRequest} from './models/UpdatePasswordRequest';
import {UpdateEmployerImageRequest} from './models/UpdateEmployerImageRequest';
import {EmployerDTO} from '../dtos/EmployerDTO';
import api from './api';
import {EmployerImageType} from '../enums/EmployerImageType';

const customOffersClient = new OffersService(api);
const customLocationsClient = new LocationsService(api);
const customCategoriesClient = new CategoriesService(api);
const customLoginClient = new LoginService(api);
const customEmployersClient = new EmployersService(api);
const customApplicationsClient = new ApplicationService(api);
const customUserService = new UserService(api);

/**
 * API calls
 */

// OFFERS
export async function getOffers() {
  return await customOffersClient.getOffers();
}

export async function getOfferById(id: string) {
  return await customOffersClient.getOfferById(id);
}

export async function postOffer(body: CreateOfferRequest) {
  return await customOffersClient.createOffer(body);
}

export async function putOffer(id: string, body: UpdateOfferRequest) {
  return await customOffersClient.putOffer(id, body);
}

export async function deleteOffer(id: string) {
  return await customOffersClient.deleteOffer(id);
}

export async function putOfferImage(offerId: string, imageId: string) {
  return await customOffersClient.putOfferImage(offerId, imageId);
}

export async function getOfferImagePresignedPostUrl(
  id: string,
  imageExtension: string,
) {
  return await customOffersClient.generatePresignedUrlForOfferImages(
    id,
    imageExtension,
  );
}

// LOCATIONS
export async function getLocations() {
  return await customLocationsClient.getLocations();
}

// CATEGORIES
export async function getCategories() {
  return await customCategoriesClient.getCategories();
}

// EMPLOYERS
export async function getEmployers() {
  // unused
  return await customEmployersClient.getEmployers();
}

export async function getEmployerById(id: string) {
  return await customEmployersClient.getEmployer(id);
}

export async function getOffersByEmployerId(employerId: string) {
  return await customEmployersClient.getOffersByEmployerId(employerId);
}

export async function putEmployer(
  id: string,
  body: UpdateEmployerRequest,
): Promise<EmployerDTO | undefined> {
  return await customEmployersClient.putEmployer(id, body);
}

export async function getEmployerImagePresignedPostUrl(
  id: string,
  imageType: EmployerImageType,
  imageExtension: string,
) {
  return await customEmployersClient.getPresignedUrlForEmployerImages(
    id,
    imageType,
    imageExtension,
  );
}

export async function putEmployerImage(
  id: string,
  body: UpdateEmployerImageRequest,
) {
  return await customEmployersClient.putEmployerImage(id, body);
}

// LOGIN
export async function logIn(data: any) {
  return await customLoginClient.signIn(data);
}

// USER
export async function getUser() {
  return await customUserService.getUser();
}

export async function changePassword(id: string, body: UpdatePasswordRequest) {
  return await customUserService.changePassword(id, body);
}

// APPLICATIONS
export async function getApplicationsForOffer(offerId: string) {
  return await customApplicationsClient.getApplicationsForOffer(offerId);
}

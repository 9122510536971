import {AxiosInstance} from 'axios';
import {UpdatePasswordRequest} from '../models/UpdatePasswordRequest';
import {ChangePasswordResponse, UserDTO} from '../../dtos/UserDTO';

export class UserService {
  constructor(private api: AxiosInstance) {}

  public async getUser(): Promise<UserDTO> {
    return this.api.get<UserDTO>('/user').then((response) => response.data);
  }

  public async changePassword(
    userId: string,
    body: UpdatePasswordRequest,
  ): Promise<ChangePasswordResponse> {
    return this.api
      .put<ChangePasswordResponse>(`/user/${userId}/password`, body)
      .then((response) => response.data);
  }
}

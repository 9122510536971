import {AxiosInstance} from 'axios';
import {CategoriesDTO} from '../../dtos/CategoryDTO';

export class CategoriesService {
  constructor(private api: AxiosInstance) {}

  public async getCategories(): Promise<CategoriesDTO> {
    return this.api
      .get<CategoriesDTO>('/categories')
      .then((response) => response.data);
  }
}

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS components
import {Alert, InputLabel} from '@mui/material';
import MDBox from 'components/MDBox';
import MDEditor from 'components/MDEditor';
import {useEffect, useState} from 'react';

// Define Component Props
interface Props {
  description: string;
  setDescription: (description: string) => void;
  errorMessage?: string;
}

/**
 * Component for editing description
 * @param param0 description props;
 * - description string,
 * - function for description change from parent component,
 * - error message from parent component
 * @returns
 */
function Description({
  description,
  setDescription,
  errorMessage,
}: Props): JSX.Element {
  const [message, setMessage] = useState('');
  useEffect(() => {
    setMessage(errorMessage);
  }, [errorMessage]);

  return (
    <MDBox
      onClick={() => {
        setMessage('');
      }}>
      <MDBox mb={2}>
        <InputLabel>
          <div style={{fontSize: 12}}>Description</div>
        </InputLabel>
      </MDBox>
      <MDEditor value={description} onChange={setDescription} />
      {message && <Alert severity='error'>{message}</Alert>}
    </MDBox>
  );
}

export default Description;

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';

interface Props {
  companyName: string;
  profileImage: string;
}

function Header({companyName, profileImage}: Props): JSX.Element {
  return (
    <Card id='profile'>
      <MDBox p={2}>
        <Grid container spacing={3} alignItems='center'>
          <Grid item>
            <MDAvatar
              src={profileImage}
              alt='profile-image'
              size='xl'
              shadow='sm'
            />
          </Grid>
          <Grid item>
            <MDBox height='100%' mt={0.5} lineHeight={1}>
              <MDTypography variant='h5' fontWeight='medium'>
                {companyName}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Header;

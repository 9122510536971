/**
 * Format date and time;
 * output format: DD.MM.YYYY hh:mm
 * @param date date-time string
 * @returns formatted string
 */
export function trimDateTime(date: string): string {
  date = date ? new Date(date).toISOString() : new Date().toISOString();
  return date.substring(0, 16);
}

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import {Dispatch, SetStateAction} from 'react';

interface Props {
  title: string;
  errorText: string;
  visibility: boolean;
  closeFunction: Dispatch<SetStateAction<void>>;
}

export const ErrorDialog = ({
  title,
  errorText,
  visibility,
  closeFunction,
}: Props) => {
  return (
    <Dialog open={visibility} onClose={() => closeFunction()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{errorText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDBox width='100%' display='flex' justifyContent='center'>
          <MDButton
            variant='gradient'
            color='info'
            onClick={() => closeFunction()}>
            go back
          </MDButton>
        </MDBox>
      </DialogActions>
    </Dialog>
  );
};

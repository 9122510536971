import {AxiosInstance} from 'axios';
import {CreateOfferRequest} from '../models/CreateOfferRequest';
import {UpdateOfferRequest} from '../models/UpdateOfferRequest';
import {OffersDTO} from '../../dtos/OffersDTO';
import {
  OfferDeleteResponse,
  OfferDTO,
  RequestOfferImageResponse,
} from '../../dtos/OfferDTO';

export class OffersService {
  constructor(private api: AxiosInstance) {}

  public async getOffers(): Promise<OffersDTO> {
    return this.api.get<OffersDTO>('/offers').then((response) => response.data);
  }

  public async getOfferById(id: string): Promise<OfferDTO> {
    return this.api
      .get<OfferDTO>(`/offer/${id}`)
      .then((response) => response.data);
  }

  public async createOffer(body: CreateOfferRequest): Promise<OfferDTO> {
    return this.api
      .post<OfferDTO>('/offer', body)
      .then((response) => response.data);
  }

  public async putOffer(
    id: string,
    body: UpdateOfferRequest,
  ): Promise<OfferDTO> {
    return this.api
      .put<OfferDTO>(`/offer/${id}`, body)
      .then((response) => response.data);
  }

  public async deleteOffer(id: string): Promise<OfferDeleteResponse> {
    return this.api
      .delete<OfferDeleteResponse>(`/offer/${id}`)
      .then((response) => response.data);
  }

  public async putOfferImage(
    offerId: string,
    imageId: string,
  ): Promise<OfferDTO> {
    return this.api
      .put<OfferDTO>(`/offer/${offerId}/image/${imageId}`)
      .then((response) => response.data);
  }

  public async generatePresignedUrlForOfferImages(
    offerId: string,
    imageExtension: string,
  ): Promise<RequestOfferImageResponse> {
    return this.api
      .post<RequestOfferImageResponse>(`/offer/${offerId}/presigned_url`, {
        imageExtension: imageExtension,
      })
      .then((response) => response.data);
  }
}

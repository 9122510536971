/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState, useEffect, useMemo} from 'react';

// react-router components
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';

// @mui material components
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Material Dashboard 2 PRO React TS exampless
import Sidenav from 'examples/Sidenav';

// Material Dashboard 2 PRO React TS themes
import theme from 'assets/theme';

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from 'assets/theme-dark';

// Material Dashboard 2 PRO React TS contexts
import {useMaterialUIController, setMiniSidenav} from 'context';

// Images
import swipeLogoWhite from 'assets/images/logo-ct.svg';
import pmiLogo from 'assets/images/logo-pmi.png';
import {RootState} from 'redux/store';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {info} from './utils/logging';
import {MENU_ENTRIES, RouteDefinition, ROUTES} from './routes';
import {fetchEmployerById} from './redux/reducers/employerSlice';
import {fetchUser} from './redux/reducers/loginSlice';
import api from './client/api';
import {isTokenExpiredErrorResponse} from './utils/ValidationErrorDetail';
import {LoginDialog} from './pages/authentication/components/LoginDialog';
import {tokenExpired} from './redux/reducers/settingsSlice';
import {toast} from 'react-toastify';
import {isDefaultTenant} from 'config';

export default function App() {
  const dispatch = useDispatch();
  const [controller, materialUIDispatch] = useMaterialUIController();
  const {miniSidenav, direction, sidenavColor, darkMode} = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const {pathname} = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(materialUIDispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(materialUIDispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    api.interceptors.response.use(
      // successful response
      async (response) => {
        return response;
      },
      // failed response
      (errorResponse) => {
        if (isTokenExpiredErrorResponse(errorResponse.response)) {
          toast.error('Credentials expired', {autoClose: 1000});
          dispatch(tokenExpired());
        } else if (errorResponse.response.status === 403) {
          toast.error('Invalid authorization token', {autoClose: 1000});
          dispatch(tokenExpired());
        }
        return Promise.reject(errorResponse);
      },
    );
  }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // jwt
  const data = useSelector((state: RootState) => state.login);
  const token = useMemo(() => data.token, [data]);
  const user = useMemo(() => data.user, [data]);

  useEffect(() => {
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete api.defaults.headers.common['Authorization'];
    }
    if (token) {
      dispatch(fetchUser());
    }
  }, [token]);

  useEffect(() => {
    if (user) {
      dispatch(fetchEmployerById(user.employer_id));
    }
  }, [user]);

  const routes = useMemo((): JSX.Element => {
    const renderRoute = (route: RouteDefinition): JSX.Element => (
      <Route path={route.route} element={route.component} key={route.name} />
    );

    if (!token) {
      const filtered = ROUTES.filter((route) => route.publicRoute).map(
        renderRoute,
      );
      info('there is no token, routes', filtered);
      return (
        <>
          <Routes>
            {filtered}
            <Route path='*' element={<Navigate to={'/auth/login'} />} />
          </Routes>
        </>
      );
    } else {
      const routes = ROUTES.map(renderRoute);
      info('rendering all routes', routes);
      return (
        <>
          <Sidenav
            color={sidenavColor}
            brand={isDefaultTenant() ? swipeLogoWhite : pmiLogo}
            brandName={isDefaultTenant() ? 'SwipeApp' : 'Swipe2Help'}
            routes={MENU_ENTRIES}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Routes>
            {routes}
            <Route path='*' element={<Navigate to={'/offers'} />} />
          </Routes>
        </>
      );
    }
  }, [token]);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <LoginDialog />
      {routes}
    </ThemeProvider>
  );
}

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useState, useEffect, ReactNode} from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';

// Material Dashboard 2 PRO React TS Base Styles
import breakpoints from 'assets/theme/base/breakpoints';

import {EmployerDTO} from '../../../../dtos/EmployerDTO';

interface Props {
  children?: ReactNode;
  employer?: EmployerDTO;
}

function EmployerHeader({children, employer}: Props): JSX.Element {
  const [tabsOrientation, setTabsOrientation] = useState<
    'horizontal' | 'vertical'
  >('horizontal');

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }
    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);
    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <MDBox position='relative' mb={5}>
      <MDBox
        display='flex'
        alignItems='center'
        position='relative'
        minHeight='18.75rem'
        borderRadius='xl'
        sx={{
          backgroundImage: () => `url(${employer.backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: '50%',
          overflow: 'hidden',
        }}
      />
      <Card
        sx={{
          position: 'relative',
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}>
        <Grid container spacing={3} alignItems='center'>
          <Grid item>
            <MDAvatar
              src={employer.profileImage}
              alt='profile-image'
              size='xl'
              shadow='sm'
            />
          </Grid>
          <Grid item>
            <MDBox height='100%' mt={0.5} lineHeight={1}>
              <MDTypography variant='h5' fontWeight='medium'>
                {employer.name}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Declaring default props for Header
EmployerHeader.defaultProps = {
  children: '',
};

export default EmployerHeader;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';

interface Language {
  key: string;
  name: string;
  flag: string;
}

// Language list (must match translations in public/locales)
const languages: Language[] = [
  {key: 'en', name: 'English', flag: '🇺🇸'},
  {key: 'pl', name: 'Polish', flag: '🇵🇱'},
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export {languages};
export default i18n;

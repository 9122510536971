import axios, {AxiosInstance} from 'axios';
import {BASE_API_URL, STAGE} from '../api/endpoints';
import {debug} from '../utils/logging';

const api: AxiosInstance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const sleep = (ms = 1500): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

api.interceptors.response.use(
  // successful response
  async (response) => {
    if (STAGE === 'localhost') {
      debug('Adding delay to request');
      await sleep();
    }
    return response;
  },
);

export default api;
